import { useContext, useMemo, useState } from "react"

import { PrefsContext } from "../../prefs/PrefsContext"
import { TimeRanges } from "../../time/timeRanges"

import { faClock } from "@fortawesome/free-regular-svg-icons"

import ModalBasic, { ButtonConfig } from "../modals/ModalBasic"
import { RelativeTimeSubPane } from "./RelativeTimeSubPane"
import { AbsoluteTimePicker } from "./AbsoluteTimePicker"
import { ButtonGroup } from "../controls/ButtonGroup/ButtonGroup"

import './ModalTimePicker.css'

interface ModalTimePickerProps {
  closer?: any
  timeRange?: string
  timeChangeFunc: any
}

export function ModalTimePicker(props: ModalTimePickerProps) {

  const parseTimeRange = (input = ''): TimeRanges => {
    const r = {} as TimeRanges
    if (!input) {
      return r
    }
    r.isRel = input.includes('w') || input.includes('d') ||
      input.includes('h') || input.includes('m') ||
      input.includes('s')
    const parts = input.split('-')
    if (r.isRel) {
      r.startSign = parts[0].startsWith('+') ? '+' : ''
      if (parts.length > 1) {
        r.endSign = parts[1].startsWith('+') ? '+' : ''
      }
    }
    r.startVal = parts[0].replaceAll('+', '')
    if (parts.length > 1) {
      r.endVal = parts[1].replaceAll('+', '')
    }
    if (r.startSign === '+') {
      r.startMode = 'future'
    } else if (r.startVal === '') {
      r.startMode = 'now'
    } else {
      r.startMode = 'past'
    }
    if (r.endSign === '+') {
      r.endMode = 'future'
    } else if (r.endVal === '') {
      r.endMode = 'now'
    } else {
      r.endMode = 'past'
    }
    return r
  }

  const defTimeRanges = parseTimeRange(props.timeRange)
  const [timeRanges, setTimeRanges] = useState<TimeRanges>(defTimeRanges)

  const defUseRelTime = props.timeRange &&
    (props.timeRange.includes('w') || props.timeRange.includes('d') ||
      props.timeRange.includes('h') || props.timeRange.includes('m') ||
      props.timeRange.includes('s'))
  const [useRelTime, setUseRelTime] = useState(defUseRelTime)

  const [relTime, setRelTime] = useState(props.timeRange || '24h')
  const [absStartTime, setAbsStartTime] = useState(props.timeRange && !defUseRelTime ? new Date(parseInt(defTimeRanges.startVal) * 1000) : new Date(Math.floor(Date.now() / 300000) * 300000 - 86400000))
  const [absEndTime, setAbsEndTime] = useState(props.timeRange && !defUseRelTime ? new Date(parseInt(defTimeRanges.endVal) * 1000) : new Date(Math.floor(Date.now() / 300000) * 300000))
  const [closeRequested, setCloseRequested] = useState(false)

  const prefs = useContext(PrefsContext)

  const timePickerKey = useMemo(() => {
    const k = {
      as: absStartTime,
      ae: absEndTime,
      rt: relTime,
      now: Date.now()
    }
    return k.now
  }, [absStartTime, absEndTime, relTime])

  const relTimeSelected = (t: string) => {
    if (relTime === t) {
      return
    }
    setRelTime(t)
  }

  const absStartSelected = (t: Date) => {
    if (absStartTime === t) {
      return
    }
    setAbsStartTime(t)
  }

  const absEndSelected = (t: Date) => {
    if (absEndTime === t) {
      return
    }
    setAbsEndTime(t)
  }

  const setTimeRange = () => {
    const t = { ...timeRanges }
    if (!useRelTime) {
      if (absStartTime) {
        t.startSign = ""
        t.startVal = Math.floor(absStartTime.getTime() / 1000).toString()
      }
      if (absEndTime) {
        t.endMode = ""
        t.endSign = ""
        t.endVal = Math.floor(absEndTime.getTime() / 1000).toString()
      }
    }
    props.timeChangeFunc(t);
    setCloseRequested(true)
  }

  const buttonConfigs = [{
    title: "Set Time Range",
    onClick: setTimeRange,
    className: "me-2"
  }] as ButtonConfig[]

  return (
    <ModalBasic
      closer={props.closer}
      closeLabel="Cancel"
      headerText="Time Range Options"
      headerIcon={faClock}
      sz="sm"
      buttonConfigs={buttonConfigs}
      closeRequested={closeRequested}
    >
      <div className="modal-time-picker-time-mode">
        <ButtonGroup
          label="Select Absolute or Relative Time"
          groupName="absOrRelTime"
          defaultValue={useRelTime}
          onClick={setUseRelTime}
          className="centered"
          buttonSize="sm"
          buttons={[
            { caption: 'Relative Times', value: true },
            { caption: 'Absolute Times', value: false }
          ]} />
        {useRelTime && <RelativeTimeSubPane relTimeSelected={relTimeSelected} timeRanges={timeRanges} timeRangeUpdated={setTimeRanges} />}
        {!useRelTime && <AbsoluteTimePicker key={timePickerKey} startTime={absStartTime} endTime={absEndTime} timeZone={prefs.tz.shortName} absEndSelected={absEndSelected} absStartSelected={absStartSelected} relativeTime={relTime} />}
      </div>
    </ModalBasic>
  )
}