import { useContext } from 'react'

import { PrefsContext } from "../../prefs/PrefsContext"
import { GetLocalizedTextItem } from '../../localization/localization'

import TextInputRow from '../forms/TextInputRow'

interface LoginEnterEmailProps {
  setCurrentEmailFunc: any
  sendEmailFunc: any
  isLoading: boolean
}

function LoginEnterEmailForm(props: LoginEnterEmailProps) {

  const prefs = useContext(PrefsContext)

  const setEmailAddress = (input: string) => {
    props.setCurrentEmailFunc(input)
  }

  return (
    <div className="login-form-body">
      <div className="login-instructions">
        {GetLocalizedTextItem(prefs.language, "signInEnterEmail")}
      </div>
      <div className="my-3 px-1">
        <TextInputRow
          label="Email"
          onChange={setEmailAddress}
          placeholder="grower@example.com"
          autoComplete={false}
          enterPressedFunc={props.sendEmailFunc}
          disabled={props.isLoading}
        />
      </div>
    </div>
  )
}

export default LoginEnterEmailForm