import './OSSCredits.css'
export default function OSSCredits() {
  return (
    <ul className="credits-list">
      <li><a href="https://fontawesome.com/" target="__new__">FontAwesome</a> - Icon Library &amp; Toolkit</li>
      <li><a href="https://react.dev/" target="__new__">React.JS</a> - Web User Interface Framework</li>
      <li><a href="https://date-fns.org/" target="__new__">Date FNS</a> - Date Formatting Library</li>
      <li><a href="https://axios-http.com/" target="__new__">Axios HTTP</a> - HTTP Client for Web Applications</li>
      <li><a href="https://www.chartjs.org/" target="__new__">Chart.JS</a> - Charting Library</li>
      <li><a href="https://www.typescriptlang.org" target="__new__">TypeScript</a> - JavaScript Language Extension</li>
    </ul>
  )
}
