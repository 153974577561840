import { useEffect, useState, useRef } from "react"
import { ButtonGroup } from "../controls/ButtonGroup/ButtonGroup"
import { PickListPane } from "../controls/PickList/PickListPane"
import TextInput from "../controls/TextInput/TextInput"

import './RelativeTimePicker.css'
import Select from "../controls/Selects/Select"

interface CustomRelativeTimeSubPaneProps {
  updateFunc: any
  defaultValue: string
}

const CustomRelativeTimeSubPane = (props: CustomRelativeTimeSubPaneProps) => {

  const [value, setValue] = useState(props.defaultValue.length > 0 ? props.defaultValue.substring(0, props.defaultValue.length - 1) : '')
  const [units, setUnits] = useState(props.defaultValue.length > 0 ? props.defaultValue.slice(-1) : 'h')
  const [mode, setMode] = useState(props.defaultValue.startsWith('+') ? 'future' : 'past')
  const inputRef = useRef<HTMLInputElement>(null)

  const { updateFunc } = props
  useEffect(() => {
    updateFunc(value, units, mode)
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [value, units, mode, updateFunc])

  return (
    <div className="custom-rel-time-pane light-panel-box">
      <TextInput className="width-65 me-2" placeholder="#" defaultValue={value} inputRef={inputRef} onChange={(e: any) => { setValue(e.target.value) }} />
      <Select className="wide me-2" defaultValue={units} onChange={(e: any) => { setUnits(e.target.value) }}>
        <option value="m">Minutes</option>
        <option value="h">Hours</option>
        <option value="d">Days</option>
        <option value="w">Weeks</option>
      </Select>
      <Select className="wide" defaultValue={mode} onChange={(e: any) => { setMode(e.target.value) }}>
        <option value='past'>Ago</option>
        <option value='future'>From Now</option>
      </Select>
    </div>
  )
}

interface RelativeTimePickerProps {
  mode: string
  updateModeFunc: any
  updateTimeFunc: any
  groupName: string
  defaultValue: string
  title: string
}

export function RelativeTimePicker(props: RelativeTimePickerProps) {

  const customTimeUpdated = (value: string, units: string, tense: string) => {
    tense = tense === 'future' ? '+' : ''
    const val = `${tense}${value}${units}`
    if (val !== props.defaultValue) {
      props.updateTimeFunc(val)
    }
  }

  return (
    <>
      <div className="rel-time-picker-section-title">
        <span>{props.title}</span>
      </div>
      <ButtonGroup
        groupName={props.groupName}
        defaultValue={props.mode}
        onClick={props.updateModeFunc}
        className="centered rel-time-button-group"
        buttons={[
          { caption: 'Now', value: 'now' },
          { caption: 'in the Past', value: 'past' },
          { caption: 'in the Future', value: 'future' },
          { caption: 'Custom', value: 'custom' }
        ]}
        buttonSize="sm"
      />
      {props.mode === 'past' &&
        <PickListPane
          defaultValue={props.defaultValue}
          values={['1h', '3h', '6h', '8h', '12h', '24h', '36h', '3d', '5d', '1w', '2w', '4w', '6w', '8w', '12w', '16w', '26w']}
          onClick={props.updateTimeFunc}
        />}
      {props.mode === 'future' &&
        <PickListPane
          defaultValue={props.defaultValue}
          values={['12h', '24h', '36h', '2d', '3d', '4d']}
          onClick={props.updateTimeFunc}
        />}
      {props.mode === 'custom' && <CustomRelativeTimeSubPane updateFunc={customTimeUpdated} defaultValue={props.defaultValue} />}
    </>
  )
}
