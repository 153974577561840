import { useEffect, useMemo, useRef, useState } from "react"

import CheckBox from "./CheckBox"

import './CheckBoxWithTextInput.css'
import TextInput from "../TextInput/TextInput"

interface CheckBoxWithTextInputProps {
  label?: string
  labelClass?: string
  defaultValue: string
  checkBoxLabel?: string
  placeholder?: string
  onChange: any
  defaultChecked?: boolean
  inputEnabledWhenUnchecked?: boolean
  id?: string
}

export function CheckBoxWithTextInput(props: CheckBoxWithTextInputProps) {

  const { id, defaultChecked, inputEnabledWhenUnchecked, label, labelClass } = props

  const [val] = useState(props.defaultValue || '')
  const [checked, setChecked] = useState(defaultChecked)
  const [clicked, setClicked] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const setNewValue = (input: string) => {
    props.onChange(input)
  }

  const valueChanged = (input: any) => {
    setNewValue(input.target.value)
  }

  useEffect(() => {
    if (clicked && !checked && inputRef.current) {
      inputRef.current.select()
      inputRef.current.focus()
    }
  }, [checked, inputRef, clicked])

  const boxClicked = () => {
    setClicked(true)
    const newChecked = !checked
    if ((newChecked && inputEnabledWhenUnchecked) || (!newChecked && !inputEnabledWhenUnchecked)) {
      setNewValue('')
    } else {
      if (inputRef.current) {
        setNewValue(inputRef.current.value)
        inputRef.current.select()
        inputRef.current.focus()
      }
    }
    setChecked(newChecked)
  }

  const computedId = useMemo(() => {
    if (!id) {
      return 'checkbox-' + (label || '').replaceAll(' ', '') +  Date.now.toString()
    }
    return id
  }, [id, label])

  const inputDisabled = useMemo(() => {
    if (inputEnabledWhenUnchecked) {
      return checked
    }
    return !checked
  }, [checked, inputEnabledWhenUnchecked])

  const labelClasses = useMemo(() => {
    const base = 'checkbox-with-text-input-label'
    if (labelClass) {
      return base + ' ' + labelClass
    }
    return base
  }, [labelClass])

  return (
    <>
      <div className="checkbox-with-text-input">
        {props.label && <div className={labelClasses}>
          {props.label}:
        </div>}
        <div className="ms-2">
          <CheckBox label={props.placeholder} defaultChecked={checked} onClick={boxClicked} id={computedId}/>
        </div>
        <div className="ms-2">
          <TextInput
            placeholder={props.placeholder}
            className="checkbox-with-text-input-input"
            disabled={inputDisabled}
            defaultValue={val}
            inputRef={inputRef}
            onChange={valueChanged}
          />
        </div>
      </div>
    </>
  )
}

