import React, { useMemo, useState } from 'react'

interface TextInputRowProps {
  onChange?: any
  onKeyDown?: any
  labelId?: string
  label?: string
  placeholder?: string
  description?: string
  inputId?: string
  autoComplete?: boolean
  autoCapitalize?: string
  autoCorrect?: boolean
  labelClass?: string
  inputClass?: string
  defaultValue?: string
  disabled?: boolean
  enterPressedFunc?: any
  className?: string
}

export default function TextInputRow (props: TextInputRowProps) {

  const [val, setVal] = useState(props.defaultValue)
  const [labelId] = useState(props.labelId ? props.labelId : 'text-input-row-label')

  const { disabled, className } = props

  const onChange = (e: any) => {
    if (props.onChange && e && e.target) {
      setVal(e.target.value)
      props.onChange(e.target.value)
    }
  }

  const onKeyDown = (e: any) => {
    if (props.enterPressedFunc && 'keyCode' in e && e.keyCode === 13) {
      if (e.preventDefault) {
        e.preventDefault()
      }
      props.enterPressedFunc()
    }
  }

  const labelClasses = useMemo(() => {
    const base = 'input-row-label'
    if (disabled) {
      return `${base} subtle`
    }
    return base
  }, [disabled])

  const classes = useMemo(() => {
    const base = 'input-group'
    if (className) {
      return `${base} ${className}`
    }
    return base
  }, [className])

  return (
    <div className={classes}>
      <span className={`input-group-text ${props.labelClass}`} id={labelId}>
        <span className={labelClasses}>{props.label}</span>
      </span>
      <input
        type="text"
        className={`form-control ${props.inputClass}`}
        id={props.inputId ? props.inputId : 'text-input-row-input'}
        placeholder={props.placeholder}
        aria-label={props.description}
        aria-describedby={labelId}
        onChange={onChange}
        onKeyDown={onKeyDown}
        autoComplete={props.autoComplete ? 'ON' : 'OFF'}
        autoCorrect={props.autoCorrect ? 'ON' : 'OFF'}
        autoCapitalize={props.autoCapitalize ? props.autoCapitalize : 'OFF'}
        defaultValue={val}
        disabled={props.disabled}
      />
    </div>
  )
}
