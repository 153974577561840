export interface TimeScaleConfig {
  unit: string
  maxTicks: number
  labelFormat?: string
}

export interface TimeScaleConfigInputs {
  timeSizeSecs?: number
  isFullScreen?: boolean
  usingMonthlyBuckets?: boolean
}

function getTimeUnit(input: TimeScaleConfigInputs): string {
  if (!input.timeSizeSecs) {
    return 'hour'
  }
  if (input.timeSizeSecs > 28 * 86400 && input.usingMonthlyBuckets) {
    return 'month'
  }
  if (input.timeSizeSecs > 1.5 * 86400) {
    return 'day'
  }
  return 'hour'
}

function getMaxTicks(input: TimeScaleConfigInputs, unit: string): number {
  if (unit === 'hour' && !input.isFullScreen) {
    return 7
  }
  return 11
}

function getTimeLabelFormat(unit: string): string | undefined {
  if (unit === 'hour') {
    return 'M/d ha'
  }
  return undefined
}

export function getTimeScaleConfig(input: TimeScaleConfigInputs): TimeScaleConfig {
  const out = {
    unit: getTimeUnit(input)
  } as TimeScaleConfig
  out.maxTicks = getMaxTicks(input, out.unit)
  out.labelFormat = getTimeLabelFormat(out.unit)
  return out
}

export function defaultTimeScaleConfig(): TimeScaleConfig {
  return {
    unit: 'hour',
    labelFormat: 'M/d ha',
    maxTicks: 8
  } as TimeScaleConfig
}