import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom";

import { faGears, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ReportDefinition, ReportRun } from "../../api/types"
import { PrefsContext } from "../../prefs/PrefsContext"
import { FlashMessageContext } from "../notifications/FlashMessage/FlashMessageContext"
import { createReportRun, getReportRun } from "../../api/endpoints"

import ModalBasic, { ButtonConfig } from "../modals/ModalBasic"
import ReportRunDetail from "./ReportRunDetail"

interface ReportRunPaneProps {
  refreshRunFunc?: any
  def: ReportDefinition
  run?: ReportRun
}

function StartRunPane(props: ReportRunPaneProps) {
  return (
    <div className="my-5 ms-4">
      Press 'Generate This Report' to start generating your files.
      {props.def.emailEnabled && 
        <div>
          <br /><br />
          The generated report will also be emailed to the configured recipients.
        </div>
      }
    </div>
  )
}

function ViewRunPane(props: ReportRunPaneProps) {

  const { run, refreshRunFunc } = props

  const checkRunStatus = useCallback(() => {
    if (run && run.status === 'new' && refreshRunFunc) {
      refreshRunFunc()
    }
  }, [run, refreshRunFunc])

  useEffect(() => {
    const f = setInterval(checkRunStatus, 2000)
    return () => {
     clearInterval(f)
    }
  }, [checkRunStatus])
  

  if (!run) {
    return <StartRunPane def={props.def} refreshRunFunc={props.refreshRunFunc} />
  }

  if (run.status === 'new') {
    return (
      <div className="my-5 ms-3">
        <h4 className="ms-2">
          <FontAwesomeIcon icon={faGears} className="me-3"/>
          Generating Report ...
        </h4>
        <div className="my-5 pb-2 mx-3">
          <p>If you leave this window open, it will automatically update when
            the report is ready.</p>
          
          <p className="pt-3">You can also close this window and check back later.</p>
        </div>
      </div>
    )
  }

  return (
    <ReportRunDetail run={run}/>
  )
}

interface ModalEditReportRunProps {
  closer?: any
  def: ReportDefinition
  initialRun?: ReportRun
}

export function ModalEditReportRun(props: ModalEditReportRunProps) {

  const [isLoading, setIsLoading] = useState(false)
  const { initialRun, def } = props
  const [run, setRun] = useState(initialRun as ReportRun | undefined)
  const { customer } = useContext(PrefsContext)
  const {setFlashMessage} =  useContext(FlashMessageContext)

  const nav = useNavigate()

  useEffect(() => {
    if (run && run.id) {
      const path = `/reports/${def.customerID}/${def.id}/${run.id}`
      if (window.location.pathname !== path) {
        nav(path, { replace: true })
      }
    }
  }, [run, def, nav])

  const refreshRun = () => {
    if (run && run.id && run.status === 'new') {
      setIsLoading(true)
      getReportRun(customer, run.id)
      .then((resp) => {
        setRun(resp)
      })
    }
  }

  const startRun = useCallback(() => {
    const r = {
      customerID: customer,
      reportDefinitionID: def.id
    } as ReportRun

    setIsLoading(true)
    createReportRun(customer, r)
      .then((resp) => {
        if ('data' in resp && 'id' in resp.data) {
          r.id = resp.data.id
          r.status = 'new'
          setRun(r)
        }
      })
      .catch((e) => {
        setFlashMessage('Could not run the Report. ' + e, 'error', 0, faTimes)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [def, customer, setFlashMessage])

  const buttonConfigs = useMemo((): ButtonConfig[] => {
    if (run) {
      return [] as ButtonConfig[]
    }
    return [{
      title: "Generate This Report",
      onClick: startRun,
      className: "me-3",
      disabled: isLoading
    }] as ButtonConfig[]
  }, [run, isLoading, startRun])
  
  return (
    <div>
      <ModalBasic
        closer={props.closer}
        closeLabel={run ? 'Close' : 'Cancel'}
        headerText={run ? 'Report Generation Results' : 'Generate: ' + def.description}
        sz="475"
        buttonConfigs={buttonConfigs}
      >
        {!run && <StartRunPane def={def} />}
        {run && <ViewRunPane def={def} run={run} refreshRunFunc={refreshRun} />}

      </ModalBasic>
    </div>
  )
}