import './PickListPane.css'

const isSelected = ((value: any, defaultValue: any, extra: string = '', trueClass: string, falseClass: string, compareNegative = false) => {
  if ((value === defaultValue) || (compareNegative && (value !== defaultValue))) {
    if (trueClass !== '') {
      if (extra === '') {
        return trueClass
      }
      return trueClass + ' ' + extra
    }
    return extra
  }
  if (falseClass !== '') {
    if (extra === '') {
      return falseClass
    }
    return falseClass + ' ' + extra
  }
  return extra
})

const isLinkSelected = ((value: any, defaultValue: any, extra: string = '', compareNegative = false) => {
  return isSelected(value, defaultValue, extra, 'picklist-link picklist-link-selected', 'picklist-link', compareNegative)
})

interface PickListPaneLinkProps {
  defaultValue?: string
  value: string
  onClick: any
}

const PickListPaneLink = (props: PickListPaneLinkProps) => {
  return (
    <>
      <span className={isLinkSelected(props.defaultValue, props.value, 'my-1')} onClick={() => props.onClick(props.value)}>{props.value}</span>
    </>
  )
}

interface PickListPaneLinksProps {
  defaultValue?: string
  onClick: any
  values: string[]
}

const PickListPaneLinks = (props: PickListPaneLinksProps) => {
  return (
    <>
      {props.values.map((val) =>
        <PickListPaneLink defaultValue={props.defaultValue} key={val} value={val} onClick={props.onClick} />
      )}
    </>
  )
}

interface PickListPaneProps {
  defaultValue?: string
  values: string[]
  onClick: any
}

export function PickListPane(props: PickListPaneProps) {

  return (
    <div className="pick-list-pane light-panel-box">
      <div className="pick-list-pane-inner">
        <PickListPaneLinks defaultValue={props.defaultValue} values={props.values} onClick={props.onClick} />
      </div>
    </div>
  )
}