import { useState, useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom';

import { PrefsContext } from "../../prefs/PrefsContext"
import { getReportDefinitionsByCustomer } from "../../api/endpoints";
import { ReportDefinition } from "../../api/types";

import ReportDefinitionsList from "../reports/ReportDefinitionsList";

export function ReportsPage() {
  const {customerAccess, customer, hasCustomer} = useContext(PrefsContext)
  const [defs, setDefs] = useState([] as ReportDefinition[])
  const [isLoading, setIsLoading] = useState(true)
  const [reloadCount, setReloadCount] = useState(0)

  const {customerId, reportId, runId} = useParams();
  const [prevCustId, setPrevCustId] = useState(customerId)
  const [prevRepId, setPrevRepId] = useState(reportId)
  
  const requestReload = () => {
    setReloadCount((val: number) => {return val + 1})
  }

  useEffect(() => {
    console.log(customerId, prevCustId, reportId, prevRepId)
    if (customerId !== prevCustId || reportId !== prevRepId) {
      setPrevCustId(customerId)
      setPrevRepId(reportId)
      requestReload()
    }
  }, [customerId, reportId, prevCustId, prevRepId])

  useEffect(() => {
    if (!customerAccess || !customer ||
      (customerId && customer !== customerId) ||
      !hasCustomer(customer)) {
      return
    }
    setIsLoading(true)
    getReportDefinitionsByCustomer(customer)
    .then((res) => {
        setDefs(res)
    })
    .catch((e) => {
      console.log(
        `ERROR loading report definitions for ${customerAccess.displayName}`,
        e, reloadCount)
    })
    .finally(() => {
      setIsLoading(false)
    })
  }, [customerAccess, setIsLoading, customer, reloadCount, customerId, hasCustomer])

  return (
    <ReportDefinitionsList
      access={customerAccess}
      defs={defs}
      isLoading={isLoading}
      requestReloadFunc={requestReload}
      selectedReportId={reportId}
      selectedRunId={runId}
      key={reloadCount}
    />
  )
}
