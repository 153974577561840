import { ReportDefinition, ReportRun } from "../../api/types"
import { GetLocalizedTextItem } from "../../localization/localization"
import { formatOffset } from "../../time/offests"

const language = 'en-us'

export const getScheduleDescription = (def: ReportDefinition) => {
  if (!def) {
    return ''
  }
  if (def.entryType === 'manual') {
    return GetLocalizedTextItem(language, 'runManually', 'upperAllFirst')
  }
  const offset = formatOffset(def.offsetSecs || 0)
  if (def.entryType === 'daily' && def.offsetSecs !== undefined) {
    return `Daily @ ${offset.formatted}`
  }
  if (def.entryType === 'weekly' && def.offsetSecs !== undefined) {
    if (offset.formatted) {
      return `Weekly @ ${offset.formatted}`
    }
    return `Weekly`
  }
  if (def.entryType === 'monthly' && def.offsetSecs !== undefined) {
    if (offset.formatted) {
      return `Monthly @ ${offset.formatted}`
    }
    return `Monthly`
  }
  return GetLocalizedTextItem(language, 'unknownCadence', 'upperAllFirst')
}

export const runStatusLabel = (run: ReportRun): string => {
  if (run.status === 'new') {
    return 'New'
  }
  if (run.status === 'success') {
    return '✓ Success'
  }
  if (run.status === 'fail') {
    return '× Failed'
  }
  return '?'
}
