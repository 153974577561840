import { Amplify, ResourcesConfig } from "aws-amplify"

 const awsCognitoConfig = {
  Auth: {
    Cognito: {
      userPoolClientId: process.env.REACT_APP_aws_cognito_app_id,
      userPoolId: process.env.REACT_APP_aws_cognito_user_pool_id,
      loginWith: {
        email: true
      }
    } 
  }
} as ResourcesConfig


export function configureAuth() {
  // this loads the AWS Amplify Configuration
  Amplify.configure(awsCognitoConfig);
}
