import { getFileServerFileByCustomer } from "../../api/endpoints";

import BasicPanel from "../controls/Panels/basic/BasicPanel"
import { ReportRun } from "../../api/types";
import { ago } from "../../time/relativeTime";
import { runStatusLabel } from "./schedule";

import './ReportRunDetail.css'

const downloadFile = ((run: ReportRun, file: string) => {
  getFileServerFileByCustomer(run.customerID, `reports/${run.id}/${file}`)
  .then((resp) => {
    window.location.href = resp.url
  })
})

interface ReportRunDetailProps {
  run: ReportRun
}

export default function ReportRunDetail(props: ReportRunDetailProps) {
  const { run } = props
  return (
    <BasicPanel className="report-definition-detail-runs">
      <div className="report-definition-detail-runs-body">
        <div className="report-definition-detail-row">
          <span className="me-2"><b>Updated:</b></span><span>{ago(run.lastModified || 0)} </span>
        </div>
        <div className="report-definition-detail-row">
          <span className="me-2"><b>Status:</b></span><span>{runStatusLabel(run)} </span>
        </div>
        <div className="report-definition-detail-row">
          <span className="me-2"><b>Execution Time:</b></span><span>{(Math.floor(run.executionTimeMS / 1000) + 1).toString()}s</span>
        </div>
        {run && run.files && run.files.length > 0 &&
          <div>
            <div className="report-definition-detail-row">
              <span className="me-2"><b>Report Files:</b></span>
            </div>
            <div className="report-file-list ms-3">
              {run.files.map((file) => {
                return (
                  <div className="report-file-list-row" key={file}>
                    -&nbsp; <label className="link-normal" onClick={() => {downloadFile(run, file)}}>{file}</label>
                  </div>
                )
              })}
            </div>
          </div>
        }
      </div>
    </BasicPanel>
  )
}