export function getUnits() {
    let units = localStorage.getItem('prefs.units')
    if (!units) {
        units = 'site'
        localStorage.setItem('prefs.units', units)
    }
    if (!['metric', 'imperial', 'site'].includes(units)) {
        units = 'site'
        localStorage.setItem('prefs.units', units)
    }
    return units
}


export function setUnitsPreference(units: string) {
    if (!units) {
        units = 'site'
    }
    if (!['metric', 'imperial', 'site'].includes(units)) {
        units = 'site'
    }
    console.log('setting units', units)
    localStorage.setItem('prefs.units', units)
    return units
}
