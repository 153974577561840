import { IQueryConfig } from '../components/chart/IQueryConfig'
import { del, get, post, put } from './cache'
import * as types from './types'

export async function getAccess() {
    let response = {} as types.UserAccess
    await get('/access', 300, 'userAccess')
    .then ((resp) => {
        response = (resp as types.UserAccess)
    })
    return response
}

export async function getAllMetricNames() {
    let response = [] as types.Metric[]
    await get('/metrics', 1800, 'allMetricNames')
    .then ((resp) => {
        response = (resp as types.Metric[])
    })
    return response
}

export async function getGroundTruthMetrics() {
    let response = {} as types.Metric[]
    await get('/historical/ground-truth/metrics', 1800, 'groundTruthMetrics')
    .then ((resp) => {
        response = (resp as types.Metric[])
    })
    return response
}

export async function getGroundTruthDevices(siteID: string) {
    let response = [] as types.DeviceInfo[]
    if (!siteID) { return response }
    await get(`/historical/ground-truth/${siteID}/devices`, 1800, 'devicesBySite-' + siteID)
    .then ((resp) => {
        response = (resp as types.DeviceInfo[])
    })
    return response
}

export async function getGroundTruthHistoricalData(cfg: IQueryConfig, timeRange: string) {
    let response = {} as any
    let url = `/historical/ground-truth/${cfg.site}/${timeRange}`
    let key = `groundTruthData.${cfg.site}.${timeRange}`
    if (cfg.node && cfg.node !== ''){
        url = `/historical/ground-truth/${cfg.site}/node/${cfg.node}/${timeRange}`
        key = `groundTruthData.${cfg.site}.${cfg.node}.${timeRange}`
    }
    url += "?responseVersion=2.1"
    if (cfg.metric) {
        url += `&metrics=${cfg.metric}`
        key += `.${cfg.metric}`
    }
    if (cfg.deviceType) {
        url += `&filter=deviceType:${cfg.deviceType}`
        key += `.${cfg.deviceType}`
    }
    if (cfg.useBuckets && cfg.bucketSize) {
        url += `&bucketSize=${cfg.bucketSize}`
        key += `.${cfg.bucketSize}`
    }
    if (cfg.useBuckets && cfg.bucketOp) {
        url += `&bucketOp=${cfg.bucketOp}`
        key += `.${cfg.bucketOp}`
    }

    url += '&disableRedirects=1'

    await get(url, 180, key)
    .then ((resp) => {
        response = (resp as types.APIResult)
    })
    return response
}

export async function getWeatherHistoricalData(cfg: IQueryConfig, timeRange: string) {
    let response = {} as any
    
    let url = `/historical/weather/${cfg.site}/${timeRange}`
    let key = `historicalWeatherData.${cfg.site}.${timeRange}`
    if (cfg.node && cfg.node !== ''){
        url = `/historical/weather/${cfg.site}/node/${cfg.node}/${timeRange}`
        key = `historicalWeatherData.${cfg.site}.${cfg.node}.${timeRange}`
    }
    url += "?responseVersion=2.1"
    if (cfg.metric) {
        url += `&metric=${cfg.metric}`
        key += `.${cfg.metric}`
    }
    if (cfg.useBuckets && cfg.bucketSize) {
        url += `&bucketSize=${cfg.bucketSize}`
        key += `.${cfg.bucketSize}`
    }
    if (cfg.useBuckets && cfg.bucketOp) {
        url += `&bucketOp=${cfg.bucketOp}`
        key += `.${cfg.bucketOp}`
    }

    await get(url, 180, key)
    .then ((resp) => {
        response = (resp as types.APIResult)
    })
    return response
}

export async function getSyntheticHistoricalData(cfg: IQueryConfig, timeRange: string) {
    let response = {} as any
    
    let url = `/synthetics/microclimate/${cfg.site}/${timeRange}`
    let key = `historicalSyntheticData.${cfg.site}.${timeRange}`
    if (cfg.node && cfg.node !== ''){
        url = `/synthetics/microclimate/${cfg.site}/node/${cfg.node}/${timeRange}`
        key = `historicalSyntheticData.${cfg.site}.${cfg.node}.${timeRange}`
    }
    url += "?responseVersion=2.1"
    if (cfg.metric) {
        url += `&metric=${cfg.metric}`
        key += `.${cfg.metric}`
    }
    if (cfg.useBuckets && cfg.bucketSize) {
        url += `&bucketSize=${cfg.bucketSize}`
        key += `.${cfg.bucketSize}`
    }
    if (cfg.useBuckets && cfg.bucketOp) {
        url += `&bucketOp=${cfg.bucketOp}`
        key += `.${cfg.bucketOp}`
    }

    await get(url, 180, key)
    .then ((resp) => {
        response = (resp as types.APIResult)
    })
    return response
}

export async function getForecastNanoclimate(cfg: IQueryConfig, timeRange: string) {
    let response = {} as any
    let sep = ''
    if (timeRange !== '') {
        sep = '/'
    }
    let url = `/predictions/microclimate/${cfg.site}${sep}${timeRange}`
    let key = `predictionsMC.${cfg.site}.${timeRange}`
    if (cfg.node && cfg.node !== ''){
        url = `/predictions/microclimate/${cfg.site}/node/${cfg.node}${sep}${timeRange}`
        key = `predictionsMC.${cfg.site}.${cfg.node}.${timeRange}`
    }
    url += "?responseVersion=2.1"
    if (cfg.metric) {
        url += `&metrics=${cfg.metric}`
        key += `.${cfg.metric}`
    }
    await get(url, 180, key)
    .then ((resp) => {
        response = (resp as types.APIResult)
    })
    return response
}

export async function getForecastWeather(cfg: IQueryConfig, timeRange: string) {
    let response = {} as any
    let sep = ''
    if (timeRange !== '') {
        sep = '/'
    }
    let url = `/predictions/weather/${cfg.site}${sep}${timeRange}`
    let key = `predictionsW.${cfg.site}.${timeRange}`
    if (cfg.node && cfg.node !== ''){
        url = `/predictions/weather/${cfg.site}/node/${cfg.node}${sep}${timeRange}`
        key = `predictionsW.${cfg.site}.${cfg.node}.${timeRange}`
    }
    url += "?responseVersion=2.1"
    if (cfg.metric) {
        url += `&metrics=${cfg.metric}`
        key += `.${cfg.metric}`
    }
    await get(url, 180, key)
    .then ((resp) => {
        response = (resp as types.APIResult)
    })
    return response
}

export async function getHistoricalWeatherMetrics() {
    let response = {} as types.Metric[]
    await get('/historical/weather/metrics', 1800, 'historicalWeatherMetrics')
    .then ((resp) => {
        response = (resp as types.Metric[])
    })
    return response
}

export async function getPredictionWeatherMetrics() {
    let response = {} as types.Metric[]
    await get('/predictions/weather/metrics', 1800, 'predictionWeatherMetrics')
    .then ((resp) => {
        response = (resp as types.Metric[])
    })
    return response
}

export async function getPredictionNanoclimateMetrics() {
    let response = {} as types.Metric[]
    await get('/predictions/microclimate/metrics', 1800, 'predictionNanoclimateMetrics')
    .then ((resp) => {
        response = (resp as types.Metric[])
    })
    return response
}

export async function getSyntheticNanoclimateMetrics() {
    let response = {} as types.Metric[]
    await get('/synthetics/microclimate/metrics', 1800, 'syntheticNanoclimateMetrics')
    .then ((resp) => {
        response = (resp as types.Metric[])
    })
    return response
}

export async function getWeatherSummaryDataByCustomer(
    customer: string,
    metric: string,
    step: number,
    count: number, 
    source: string | undefined = undefined,
    afterStep: number | undefined = undefined,
    afterCount: number | undefined = undefined) {

    let response = {} as types.SummaryData
    let url = `summaries/microclimate/customer/${customer}?metric=${metric}&step=${step}&count=${count}`
    let key = `weatherSummaryData.${customer}.${metric}.${step}.${count}`
    if (source !== undefined) { url += `&source=${source}`; key += `.${source}` }
    if (afterStep !== undefined) { url += `&afterStep=${afterStep}`; key += `.${afterStep}` }
    if (afterCount !== undefined) { url += `&afterCount=${afterCount}`; key += `.${afterCount}` }

    await get(url, 1800, key)
    .then ((resp) => {
        response = (resp as types.SummaryData)
    })
    return response
}

export async function getReportDefinitionsByCustomer(customer: string)
{
    const url = `report-definitions/customer/${customer}`
    let response = [] as types.ReportDefinition[]
    await get(url)
    .then ((resp) => {
        response = (resp as types.ReportDefinition[])
    })
    return response
}

export async function updateReportDefinition(customer: string, id: string, def: types.ReportDefinition)
{
    const url = `report-definitions/${customer}/${id}`
    let response = {} as any
    await put(url, def, `report-definitions.customer.${customer}`)
    .then ((resp) => {
        response = (resp as any)
    })
    return response
}

export async function createReportDefinition(customer: string, def: types.ReportDefinition)
{
    const url = `report-definitions/customer/${customer}`
    let response = {} as any
    await post(url, def, `report-definitions.customer.${customer}`)
    .then ((resp) => {
        response = (resp as any)
    })
    return response
}

export async function createReportRun(customer: string, run: types.ReportRun)
{
    const url = `report-runs/customer/${customer}`
    let response = {} as any
    await post(url, run, `report-runs.customer.${customer}`)
    .then ((resp) => {
        response = (resp as any)
    })
    return response
}

export async function getReportRun(customer: string, id: string)
{
    const url = `report-runs/${customer}/${id}`
    let response = {} as any
    await get(url)
    .then ((resp) => {
        response = (resp as types.ReportRun)
    })
    return response
}

export async function getReportRunsByDefId(customer: string, id: string)
{
    const url = `/report-runs/report-definition-id/${customer}/${id}`
    let response = {} as any
    await get(url)
    .then ((resp) => {
        response = (resp as types.ReportRun[])
    })
    return response
}

export async function deleteReportDefinition(customer: string, id: string)
{
    const url = `report-definitions/${customer}/${id}`
    let response = {} as any
    await del(url, `report-definitions.customer.${customer}`)
    .then ((resp) => {
        response = (resp as any)
    })
    return response
}

export async function getFileServerFileByCustomer(customer: string, file: string)
{
    const url = `fileserver/customer/${customer}?file=${file}`
    let response = {} as types.FileserverFile
    await get(url)
    .then ((resp) => {
        response = (resp as types.FileserverFile)
    })
    return response
}

export async function getLibraryChartsByCustomer(customerID: string)
{
    const url = `library-charts/customer/${customerID}`
    let response = {} as types.LibraryChart[]
    await get(url, 600, `library-charts.customer.${customerID}`)
    .then ((resp) => {
        response = (resp as types.LibraryChart[])
    })
    return response
}

export async function createLibraryChart(customerID: string, data: any)
{
    const url = `library-charts/customer/${customerID}`
    let response = {} as types.CreatedResponse
    await post(url, data, `library-charts.customer.${customerID}`)
    .then ((resp) => {
        response = (resp as any)
    })
    return response
}

export async function deleteLibraryChart(customerID: string, chartID: string)
{
    const url = `library-charts/${customerID}/${chartID}`
    let response = {} as types.CreatedResponse
    await del(url, `library-charts.customer.${customerID}`)
    .then ((resp) => {
        response = (resp as any)
    })
    return response
}

export async function updateLibraryChart(customerID: string, chartID: string, data: any)
{
    const url = `library-charts/${customerID}/${chartID}`
    let response = {} as types.CreatedResponse
    await put(url, data, `library-charts.customer.${customerID}`)
    .then ((resp) => {
        response = (resp as any)
    })
    return response
}

export async function postFeedback(data: types.FeedbackSubmission)
{
    const url = `feedback`
    let response = {} as types.CreatedResponse
    await post(url, data)
    .then ((resp) => {
        response = (resp as any)
    })
    return response
}

export async function getApiKeyAccess()
{
    const url = `apikey-access`
    let response = {} as types.ApiKeyAccessInfo
    await get(url)
    .then ((resp) => {
        response = (resp as types.ApiKeyAccessInfo)
    })
    return response
}

export async function rotateApiKey()
{
    const url = `apikey`
    let response = {} as types.ApiKeyInfo
    await post(url)
    .then ((resp) => {
        response = (resp as any)
    })
    return response
}
