import SelectRow from '../forms/SelectRow'

interface FolderSelectorProps {
  onChange?: any
  labelId?: string
  label?: string
  description?: string
  selectId?: string
  labelClass?: string
  selectClass?: string
  defaultValue?: string | number
}

export default function FolderSelector (props: FolderSelectorProps) {
  let labelId = "text-select-row-label"
  let selectId = "text-select-row-select"
  if (props.labelId) {
    labelId = props.labelId
  }
  if (props.selectId) {
    selectId = props.selectId
  }

  return (
    <SelectRow 
      labelClass={props.labelClass}
      selectClass={props.selectClass}
      selectId={selectId}
      onChange={props.onChange}
      defaultValue={props.defaultValue}
      label={props.label}
      labelId={labelId}
      description={props.description}
    >
        <option value="Climate">Climate</option>
        <option value="Soil">Soil</option>
        <option value="Water">Water</option>
        <option value="Air Quality">Air Quality</option>
        <option value="CO2 & Greenhouse Gases">CO2 &amp; Greenhouse Gases</option>
        <option value="Miscellaneous">Miscellaneous</option>
    </SelectRow>
  )

}
