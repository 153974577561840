import { useContext, useMemo } from 'react'

import { PrefsContext } from '../../prefs/PrefsContext'
import { SiteAccess } from '../../api/types'
import { newUUID } from "../../uuid"

import Select from '../controls/Selects/Select'

import './SiteAndNodeSelector.css'

interface SiteAndNodeSelectorProps {
  className?: string
  labelClass?: string
  siteId?: string
  nodeId?: string
  onSiteChange?: any
  onNodeChange?: any
  hideLabels?: boolean
  hideSelectASite?: boolean
  showAllSitesOption?: boolean
  wrapperClass?: string
}

export default function SiteAndNodeSelector(props: SiteAndNodeSelectorProps) {
  const prefs = useContext(PrefsContext)

  const { siteId, hideLabels, nodeId, wrapperClass } = props
  const { customerAccess } = prefs

  const siteLabel = useMemo(() => {
    if (hideLabels) {
      return undefined
    }
    return 'Site'
  }, [hideLabels])

  const nodeLabel = useMemo(() => {
    if (hideLabels) {
      return undefined
    }
    return 'Node'
  }, [hideLabels])

  const siteSelectKey = useMemo(() => {
    const out = {
      s: siteId,
      access: customerAccess,
      id: newUUID()
    }
    return out.id
  }, [customerAccess, siteId])

  const siteAccess = useMemo(() => {
    if (customerAccess && customerAccess.sites &&
      siteId && siteId in customerAccess.sites) {
      return customerAccess.sites[siteId]
    }
    return {} as SiteAccess
  }, [customerAccess, siteId])

  const nodeSelectKey = useMemo(() => {
    const out = {
      s: siteAccess,
      i: siteId,
      b: nodeId,
      id: newUUID()
    }
    return out.id
  }, [siteAccess, siteId, nodeId])

  const wrapperClasses = useMemo(() => {
    const base = ''
    if (wrapperClass) {
      return `${base} ${wrapperClass}`
    }
    return base
  }, [wrapperClass])

  return (
    <div className={wrapperClasses}>
      <Select
        label={siteLabel}
        labelClass={props.labelClass}
        className={`wide mb-2 ${props.className}`}
        onChange={props.onSiteChange}
        defaultValue={siteId}
        key={siteSelectKey}
      >
        {props.showAllSitesOption && <option value="all">All Sites</option>}
        {!props.hideSelectASite && <option value="">-- Select a Site --</option>}
        {customerAccess && 
        Object.keys(customerAccess.sites).map((key: string) => {
          return (
            <option key={key} value={key}>
              {customerAccess?.sites[key].displayName}
            </option>
          )
        })}
      </Select>
      <Select
        label={nodeLabel}
        labelClass={props.labelClass}
        className={`wide mb-2 ${props.className}`}
        disabled={!siteId}
        onChange={props.onNodeChange}
        defaultValue={props.nodeId}
        key={nodeSelectKey}
      >
        <option value="">All Nodes</option>
        {siteAccess && siteAccess.nodes &&
          siteAccess.nodes.map((node) => {
          return (
            <option key={node.id} value={node.id}>
            {node.displayName}
          </option>
          )
        })}
      </Select>
    </div>
  )
}

