import { CustomerAccess } from "../api/types";

export function IsCustomerAdmin(access: CustomerAccess): boolean {
    for (const k in access.sites) {
        if (access.sites[k].role === 'admin') {
            return true
        }
    }
    return false
}

export function IsCustomerEditor(access: CustomerAccess): boolean {
    for (const k in access.sites) {
        if (access.sites[k].role === 'admin' ||
            access.sites[k].role === 'editor') {
            return true
        }
    }
    return false
}


export function IsCustomerViewer(access: CustomerAccess): boolean {
    for (const k in access.sites) {
        if (access.sites[k].role === 'admin' ||
            access.sites[k].role === 'editor' ||
            access.sites[k].role === 'viewer') {
            return true
        }
    }
    return false
}
