import { Navigate, Outlet, Route } from 'react-router-dom';
import { useAuth } from '../auth/provider'

import AppHeader from '../components/scaffolding/header/AppHeader'
import AppFooter from '../components/scaffolding/AppFooter'
import { LeftNav } from "../components/scaffolding/LeftNav";

import { Overview } from '../components/views/Overview'
import { Charts } from '../components/views/Charts'
import { Reports } from '../components/views/Reports'
import { Alerting } from '../components/views/Alerting'
import { Maintenance } from '../components/views/Maintenance'
import { Sites } from '../components/views/Sites'
import { Access } from '../components/views/Access'
import { ChangeCustomer } from '../components/views/ChangeCustomer';

// SecureRoute means login is required to access these resources
export default function SecureRoutes() {

  const { isAuthenticated, isInitializing } = useAuth()
  if (isInitializing) {
    return <div>Loading ...</div>
  }
  if (!isAuthenticated) {
    return <Navigate to='/login'/>
  }
  return <>
    <AppHeader />
    <div className="container secure-app">
      <LeftNav />
      <Outlet />
    </div>
    <AppFooter />
  </>
}

export const secureRouteChildren = <>
  <Route index={true} element={<Overview />} />

  <Route path="/customer/:customerId" element={<ChangeCustomer />} />

  <Route path="/charts" element={<Charts />} />
  <Route path="/charts/:section" element={<Charts />} />
  <Route path="/charts/:section/:customerId/:elementId" element={<Charts />} />


  <Route path="/reports" element={<Reports />} />
  <Route path="/reports/:customerId/:reportId" element={<Reports />} />
  <Route path="/reports/:customerId/:reportId/:runId" element={<Reports />} />

  <Route path="/alerting" element={<Alerting />} />
  <Route path="/maintenance" element={<Maintenance />} />
  <Route path="/sites" element={<Sites />} />
  <Route path="/access" element={<Access />} />
</>