import { useContext, useMemo } from "react"

import { PrefsContext } from "../../../prefs/PrefsContext"
import { GetLocalizedTextItem } from "../../../localization/localization"
import { item } from "./item"

import { ListBoxItem } from "./ListBoxItem"

import './ListBox.css'

interface ListBoxProps {
    isLoading?: boolean
    items: item[]
    title?: string
    className?: string
    loadingText?: string
    noItemsText?: string
    errorText?: string // TODO: implement
}

export function ListBox(props: ListBoxProps) {

  const { language } = useContext(PrefsContext)

  const { items, isLoading, loadingText, noItemsText } = props

  const mLoadingText = useMemo(() => {
    if (loadingText) {
      return loadingText
    }
    return GetLocalizedTextItem(language, 'loadingEllipses', 'upperAllFirst')
  }, [loadingText, language])

  const mNoItemsText = useMemo(() => {
    if (noItemsText) {
      return noItemsText
    }
    return GetLocalizedTextItem(language, 'noItems', 'upperAllFirst')
  }, [noItemsText, language])

  return (
    <div className={props.className}>
      {props.title && <div className="mb-2 ms-1 font-reduce-2">{props.title}</div>}
      <div className="list-box-container">
        {items && items.map((item: item) => {
          return (
            <ListBoxItem item={item} key={item.id} />
          )
        })}
        {isLoading && 
          <div className="subtle mt-3 ms-3">{mLoadingText}</div>
        }
        {!isLoading && items.length === 0 && 
          <div className="subtle mt-3 ms-3">{mNoItemsText}</div>
        }
      </div>
    </div>
  )
}
