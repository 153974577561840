import { useContext, useEffect, useState } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { PrefsContext } from "../../prefs/PrefsContext"

export function ChangeCustomer() {

  const [hasChanged, setHasChanged] = useState(false)
  const { customerId } = useParams()
  const { customer, setCustomerFunc } = useContext(PrefsContext)
  const [queryParams] = useSearchParams()
  const newCustomer = customerId || customer
  const nav = useNavigate()

  let from = queryParams.get('from')
  if (from) {
    const urlEnd = from.indexOf('/' + customer)
    if (urlEnd > 0) {
      from = from.substring(0, urlEnd)
    }
  }

  setTimeout(() => {
    console.log('setting customer to', newCustomer)
    setCustomerFunc(newCustomer)
  }, 200)

  useEffect(() => {
    if (hasChanged) {
      return
    }
    const to = from || '/'
    console.log('navigating to', to)
    setTimeout(() => { nav(to, {replace: true}) }, 400)
    setHasChanged(true)
  }, [from, nav, setHasChanged, hasChanged])

  return (
    <>
      <div className="content-pane">
        <h3>Loading ...</h3>
      </div>
    </>
  )
}
