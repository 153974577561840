// import { RenderedLayout } from "../layouts/RenderedLayout";
// import { DefaultOverviewLayout } from "../layouts/defaultLayouts";

// export function Overview() {
//   return (
//     <>
//       <RenderedLayout layout={DefaultOverviewLayout()}/>
//     </>
//   )
// }

// // TEMPORARY Welcome Page

import { useContext, useMemo } from "react"
import { PrefsContext } from "../../prefs/PrefsContext"

export function Overview() {

  const prefs = useContext(PrefsContext)
  const {customerAccess} = prefs
  
  const customerDN = useMemo(() => {
    if (!customerAccess) {
      return "your team"
    }
    return customerAccess.displayName
  }, [customerAccess])

  return (
    <div className="content-pane" style={{marginLeft: '230px', marginTop: '25px'}}>
      
      <h4>Welcome to Agrology's all-new Grower Portal</h4>
      <hr className="mt-3" />
      <div className="mt-3" style={{width: "600px", lineHeight: 1.5}}>
        <p>Hey there, thanks for stopping by!</p>
        
        <b className="highlight">About the Portal</b>
        <p>The Grower Portal is a place for you to explore your data and better
        understand the health of your crops, right from your Desktop Web
        Browser. Here, you'll also be able to manage self-service items
        that have been traditionally handled by communicating with Agrology
        staff.</p>
                  
        <b className="highlight">In this First Release</b>
        <p>Today we're providing a powerful new <b>Charts</b> capability, which
        allows you to explore all of your data in real time. To get you
        started, {customerDN}'s Chart Library has been pre-populated with some
        useful charts. But we encourage you also to try out the more advanced
        Chart Builder, which allows you to create your own charts
        and save them to {customerDN}'s Chart Library.</p>
        
        <b className="highlight">Coming Soon</b>
        <p>We'll be adding many new capabilities to the Grower Portal over the
        next several weeks, so stay tuned! Here's a run-down of some forthcoming
        features:</p>
        <ul>
          <li>Overview - bubbles up information that is most important to your operations</li>
          <li>Reports - generate CSVs or PDFs, and schedule recurring email delivery</li>
          <li>Alerts - view your environmental alerts and customize alerting thresholds</li>
          <li>Access Control - manage your team's access to the App and Grower Portal</li>
        </ul>
      </div>

    </div>
  )
}
