import {api, getWithRedirectSupport} from "./client";

const ds = {};

// function deleteCacheEntry (cacheKey, itemKey = '', listIndex = -1) {
//   if (cacheKey in ds) {
//     if (ds[cacheKey] && listIndex > -1 &&
//       Object.prototype.toString.call(ds[cacheKey]) === '[object Array]') {
//       delete ds[cacheKey][listIndex]
//     } else if (ds[cacheKey] && itemKey) {
//       delete ds[cacheKey][itemKey]
//     } else {
//       delete ds[cacheKey]
//     }
//   }
// };

export async function del (path, cacheKey = '') {
  if (cacheKey && cacheKey in ds) {
    delete ds[cacheKey]
  }
  return api.delete(path)
};

export async function post (path, data, cacheKey = '') {
  if (cacheKey && cacheKey in ds) {
    delete ds[cacheKey]
  }
  return api.post(path, data)
};

export async function put (path, data, cacheKey = '') {
  if (cacheKey && cacheKey in ds) {
    delete ds[cacheKey]
  }
  return api.put(path, data)
};

export async function getNoCache (path) {
  return api.get(path)
};

export async function get (path, ttl = -1, cacheKey = '', itemKey = '', keyfield = '') {
  ttl *= 1000
  let resp = {}
  const cacheKeyExp = cacheKey + '-exp'
  const cacheKeyPF = cacheKey + 'IsPartial'
  let cacheHit = false
  if (ttl >= 0 && cacheKey && cacheKey in ds) {
    resp = ds[cacheKey]
    cacheHit = true
    if ((!itemKey && resp && ds[cacheKeyPF]) || (resp && itemKey && !(itemKey in resp))) {
      resp = {}
      cacheHit = false
    } else if (itemKey && resp && itemKey in resp) {
      resp = resp[itemKey]
      cacheHit = true
    }
    if (cacheHit && resp && ((ttl === 0) ||
      (ttl > 0 && cacheKeyExp in ds && ds[cacheKeyExp] >= Date.now()))) {
      return resp
    }
  }
  await getWithRedirectSupport(path)
    .then(r => {
      if (ttl >= 0 && cacheKey) {
        if (itemKey) {
          if (!(cacheKey in ds)) {
            // add item to the cache key, but mark the key as partial data
            ds[cacheKey] = {}
            ds[cacheKeyPF] = true
            if (ttl > 0) {
              ds[cacheKeyExp] = Date.now() + ttl
            }
          }
          ds[cacheKey][itemKey] = r
        } else {
          ds[cacheKey] = r
          ds[cacheKeyPF] = false
          if (ttl > 0) {
            ds[cacheKeyExp] = Date.now() + ttl
          }
        }
      }
      resp = r
    })
    .catch(error => {
      console.log('error fetching :', error)
      resp = error
    })
  if (resp && keyfield && Object.prototype.toString.call(resp) === '[object Array]') {
    const dict = {}
    for (const i in resp) {
      dict[resp[i][keyfield]] = resp[i]
    }
    resp = dict
  }
  return resp
};
