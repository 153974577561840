import { useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faEdit } from '@fortawesome/free-regular-svg-icons'
import { ModalTimePicker } from './ModalTimePicker'
import { formatTimerange } from '../../time/timeRanges'

import './TimePicker.css'
import BasicPanel from '../controls/Panels/basic/BasicPanel'

interface TimePickerProps {
  timeRange?: string
  timeChangeFunc: any
}

export default function TimePicker(props: TimePickerProps) {

  const [isPickerVisible, setIsPickerVisible] = useState(false)

  const { timeRange } = props

  const formattedTimerange = useMemo((): string => {
    const tr = timeRange || '24h-'
    return formatTimerange(tr)
  }, [timeRange])

  const className = (input: string) => {
    if (input.length > 14) {
      return "flex-grow-1 hoverable-text font-reduce-2"
    }
    return "flex-grow-1 hoverable-text"
  }

  return (
    <>
      <BasicPanel className="control">
        <div className="timepicker-box expand-box expand-box-hoverable">
          <div
            className="timepicker-box-label"
            onClick={() => { setIsPickerVisible(true)}}>
            <FontAwesomeIcon icon={faClock} className="hoverable-icon me-2" />
            <div className={className(formattedTimerange)}>{formattedTimerange}</div>
            <FontAwesomeIcon icon={faEdit} className="hoverable-icon" />
          </div>
        </div>
      </BasicPanel>
      {isPickerVisible && <ModalTimePicker closer={() => setIsPickerVisible(false)} timeRange={timeRange} timeChangeFunc={props.timeChangeFunc} />}
    </>
  )

}