import ReactDOM from 'react-dom/client'
import { BrowserRouter } from "react-router-dom"
import App from './App'

import { configureAuth } from './auth/config'
import { loadTheme } from './prefs/theme'

import './index.css'

configureAuth()
loadTheme()

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
