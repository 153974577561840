import { PrefsContext } from "../../prefs/PrefsContext"

import { GetLocalizedTextItem, Interpolate } from "../../localization/localization";
import { useContext } from "react";
import TextInputRow from "../forms/TextInputRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";


interface LoginEnterVerificationCodeFormProps {
  setVerificationCodeFunc: any
  sendVerificationCodeFunc: any
  userEmail: string
  isLoading: boolean
}

export default function LoginEnterVerificationCodeForm(props: LoginEnterVerificationCodeFormProps) {

  const prefs = useContext(PrefsContext)

  return (
    <div className="login-form-body login-code-validation">
      <div className="login-instructions">
        <FontAwesomeIcon icon={faEnvelope} className="me-3" />
        {Interpolate(GetLocalizedTextItem(prefs.language, "signInWeSentEmail"), [props.userEmail])}
        <hr className="mt-3"/>
      </div>

      <div className="centered mt-2">
        {GetLocalizedTextItem(prefs.language, "signInCheckInbox")}
        <div className="font-increase-1 bold">
          support@agrology.ag
        </div>
      </div>

      <div className="centered mt-3 mb-3 ms-4 me-4">
        {GetLocalizedTextItem(prefs.language, "signInEnterCode")}
      </div>

      <div className="centered mt-3 mb-4 px-4">
        { Interpolate(GetLocalizedTextItem(prefs.language, "signInCodeExpires"), ['5m'])}
      </div>

      <hr />
      <div className="mt-3 px-1">
        <TextInputRow
          label="Code"
          onChange={props.setVerificationCodeFunc}
          autoComplete={false}
          enterPressedFunc={props.sendVerificationCodeFunc}
          disabled={props.isLoading}
        />
      </div>
    </div>
  )
}
