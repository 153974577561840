import { useCallback, useContext, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { PrefsContext } from "../../prefs/PrefsContext"
import { newUUID } from "../../uuid"
import { ChartDataToCSV } from "./csv"
import { getHash } from './hash'

import { Chart as ChartJS } from 'chart.js/auto'
import { Chart as GeneralChart } from 'react-chartjs-2'
import ChartjsPluginWatermark from './watermarkPlugin'
import { placeWatermark } from './watermarkPlacement'
import 'chartjs-adapter-date-fns'

import { IChartJSData } from './IChartJSData'
import { IChartOptions } from './ChartOptions'
import { IQueryConfig, IChartDisplayConfig, IChartSettings } from './IQueryConfig'
import { LibraryChart } from '../../api/types'

import FullScreenToggle from '../controls/FullScreenToggle/FullScreenToggle'
import { ChartMenu } from './ChartMenu'
import { ModalSaveChart } from './ModalSaveChart'
import { ModalEditChartColors } from './ModalEditChartColors'
import { ModalDeleteLibraryChart } from './ModalDeleteLibraryChart'
import { ModalRelocateLibraryChart } from './ModalRelocateLibraryChart'

import './ChartView.css'

ChartJS.register(
  ChartjsPluginWatermark,
)

interface ChartViewProps {
  className?: string
  watermarkLocation?: string
  chartData?: IChartJSData
  chartOptions?: IChartOptions
  isLoading?: boolean
  containerSource?: string
  queryConfigs?: IQueryConfig[]
  displayConfig?: IChartDisplayConfig
  timeRange?: string
  requestReload: any
  libraryChart?: LibraryChart
  libraryChartLoader?: any
}

function placeLegendTitles(chartOptions: IChartOptions, chartData: IChartJSData) {
  if (chartOptions.scales.y.display && chartData && chartData.y1AxisTitle) {
    chartOptions.scales.y.title.text = chartData.y1AxisTitle
    chartOptions.scales.y.title.display = true
  } else {
    chartOptions.scales.y.title.display = false
  }
  if (chartOptions.scales.y2.display && chartData && chartData.y2AxisTitle) {
    chartOptions.scales.y2.title.text = chartData.y2AxisTitle
    chartOptions.scales.y2.title.display = true
  } else {
    chartOptions.scales.y2.title.display = false
  }
}

export default function ChartView(props: ChartViewProps) {

  const { className, chartData, chartOptions, containerSource, watermarkLocation, queryConfigs, displayConfig, timeRange, libraryChart } = props
  const [isSaveVisible, setIsSaveVisible] = useState(false)
  const [isDeleteVisible, setIsDeleteVisible] = useState(false)
  const [isMoveVisible, setIsMoveVisible] = useState(false)
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [isColorEditorVisible, setIsColorEditorVisible] = useState(false)
  const prefs = useContext(PrefsContext)
  const nav = useNavigate()

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen)
  }

  const chartSettings = useMemo((): IChartSettings => {
    return {
      queryConfigs: queryConfigs,
      displayConfig: displayConfig
    } as IChartSettings
  }, [queryConfigs, displayConfig])

  const isLibraryChart = useMemo(() => {
    return containerSource === 'library'
  }, [containerSource])

  const isBuilderChart = useMemo(() => {
    return containerSource === 'builder'
  }, [containerSource])

  const canOpeninBuilder = useMemo(() => {
    return queryConfigs !== undefined && queryConfigs.length > 0 && timeRange !== undefined && timeRange !== '' && displayConfig !== undefined
  }, [queryConfigs, displayConfig, timeRange])

  const openInBuilder = () => {
    if (!canOpeninBuilder) {
      return
    }
    var title = libraryChart && libraryChart.description ? libraryChart.description : undefined
    const hash = getHash(prefs.customer, timeRange || '', queryConfigs, displayConfig, title)
    if (hash === '') {
      return
    }
    nav('/charts/builder#' + hash, {replace: true})
  }

  const chartLabels = useMemo((): string[] => {
    if (!chartData || !chartData.datasets) {
      return []
    }
    const out = [] as string[]
    for (const ds of chartData.datasets) {
      if (ds.label) {
        out.push(ds.label)
      }
    }
    return out
  }, [chartData])

  const legendClicked = useCallback((e: any, i: any, l: any) => {
    console.log("LEGEND CLICKED", e, i, l)
    const index = i.datasetIndex
    const ci = l.chart
    if (ci.isDatasetVisible(index)) {
        ci.hide(index)
        i.hidden = true
    } else {
        ci.show(index)
        i.hidden = false
    }
  }, [])

  const processedChartOptions = useMemo(() => {
    if (!chartOptions || !chartData) {
      return {} as IChartOptions
    }
    const out = { ...chartOptions } as IChartOptions
    placeLegendTitles(out, chartData)
    placeWatermark(out, chartData, watermarkLocation)
    out.plugins.legend.onClick = legendClicked
    return out
  }, [chartOptions, chartData, watermarkLocation, legendClicked])

  const saveTitle = useMemo(() => {
    if (processedChartOptions && processedChartOptions.plugins &&
      processedChartOptions.plugins.title && processedChartOptions.plugins.title.text) {
        return processedChartOptions.plugins.title.text
    }
  }, [processedChartOptions])

  const chartKey = useMemo(() => {
    const x = {
      d: chartData,
      o: processedChartOptions,
      k: newUUID()
    } as any
    return x.k
  }, [chartData, processedChartOptions])

  const hasData = useMemo(() => {
    if (!chartData || !chartData.datasets || !chartData.labels ||
      chartData.datasets.length === 0 || chartData.labels.length === 0) {
        return false
      }
      return true
  },[chartData])

  const chartViewClasses = useMemo(() => {
    let baseClasses = 'chart-view'
    if (isFullScreen) {
      baseClasses += ' chart-view-fs'
    }
    if (!className) {
      return baseClasses
    }
    return `${baseClasses} ${className}`
  }, [className, isFullScreen])

  const downloadCanvasAsImage = () => {
    let filename = 'AgrologyChart.'
    if (chartOptions && chartOptions.plugins &&
      chartOptions.plugins.title && chartOptions.plugins.title.text) {
      filename += chartOptions.plugins.title.text + '.'
    }
    filename += 'png'
    const downloadLink = document.createElement('a')
    downloadLink.setAttribute('download', filename)
    const canvas = document.getElementById(chartKey) as HTMLCanvasElement
    if (canvas) {
      canvas.toBlob((blob) => {
        if (blob) {
          let url = URL.createObjectURL(blob)
          downloadLink.setAttribute('href', url)
          downloadLink.click()
        }
      })
    }
  }

  const downloadCSV = () => {
    const output = ChartDataToCSV(chartData as any)
    let filename = 'AgrologyChart.'
    if (chartOptions && chartOptions.plugins &&
      chartOptions.plugins.title && chartOptions.plugins.title.text) {
      filename += chartOptions.plugins.title.text + '.'
    }
    filename += 'csv'
    const downloadLink = document.createElement('a')
    downloadLink.setAttribute('download', filename)
    let url = URL.createObjectURL(new Blob([output], { type : 'application/csv' }))
    downloadLink.setAttribute('href', url)
    downloadLink.click()
  }

  // Rendering:

  return (
    <div className={chartViewClasses}>
      {props.isLoading &&
        <div className="chart-container chart-view-loading loading-text">
          Loading Chart Data ...
        </div>
      }
      {!props.isLoading && !hasData &&
        <div className="chart-container">
          {isLibraryChart && prefs.isCustomerEditor && <ChartMenu
            deleteFromLibraryFunc={() => {setIsDeleteVisible(true)}}
            canDeleteFromLibrary={true}
            canSaveToLibrary={false}
            canOpenInBuilder={false}
            hideDownloaders={true}
          />}
          {isFullScreen && <FullScreenToggle />}
          <div className="chart-view-loading loading-text">
            No Data
          </div>
          {isDeleteVisible &&
          <ModalDeleteLibraryChart
            customerDN={prefs.customerAccess?.displayName}
            customerID={prefs.customerAccess?.customer}
            chartID={props.libraryChart?.id || ''}
            closer={() => {setIsDeleteVisible(false); if (props.libraryChartLoader) {props.libraryChartLoader()}}}
          />}
        </div>
      }
      {!props.isLoading && hasData &&
      <>
        <div className="chart-container">
          {hasData && <ChartMenu
            showColorEditorFunc={() => {setIsColorEditorVisible(true)}}
            saveChartToLibraryFunc={() => {setIsSaveVisible(true)}}
            deleteFromLibraryFunc={() => {setIsDeleteVisible(true)}}
            moveChartToFolderFunc={() => {setIsMoveVisible(true)}}
            openInBuilderFunc={() => {openInBuilder()}}
            downloadCSVFunc={downloadCSV}
            downloadPNGFunc={downloadCanvasAsImage}
            canDeleteFromLibrary={prefs.isCustomerEditor && isLibraryChart && props.queryConfigs && props.queryConfigs.length !== 0}
            canSaveToLibrary={prefs.isCustomerEditor && isBuilderChart && props.queryConfigs && props.queryConfigs.length !== 0}
            canOpenInBuilder={isLibraryChart && canOpeninBuilder}
            canMoveFolders={prefs.isCustomerEditor && isLibraryChart}
          />}
          <FullScreenToggle isFullScreen={isFullScreen} onClick={toggleFullScreen} />
          <GeneralChart
            id={chartKey}
            type="line"
            options={processedChartOptions}
            redraw={true}
            data={chartData as any}
            key={chartKey}
            className="general-chart"
          />
        </div>
        {hasData && 
          <div className="chart-controls-row">
            <span>
              Times are in {prefs.tz.shortName}
            </span>
          </div>
        }
        {isColorEditorVisible && 
        <ModalEditChartColors
          closer={() => {setIsColorEditorVisible(false); props.requestReload(true)}}
          chartLabels={chartLabels}
        />
        }
        {isSaveVisible && 
        <ModalSaveChart
          closer={() => {setIsSaveVisible(false)}}
          customerDN={prefs.customerAccess?.displayName}
          customerID={prefs.customerAccess?.customer}
          title={saveTitle}
          chartDefinition={JSON.stringify(props.queryConfigs)}
          chartSettings={JSON.stringify(chartSettings)}
        />
        }
        {isDeleteVisible &&
        <ModalDeleteLibraryChart
          customerDN={prefs.customerAccess?.displayName}
          customerID={prefs.customerAccess?.customer}
          chartID={props.libraryChart?.id || ''}
          closer={() => {setIsDeleteVisible(false); if (props.libraryChartLoader) {props.libraryChartLoader()}}}
        />
        }
        {isMoveVisible && props.libraryChart &&
        <ModalRelocateLibraryChart
          customerDN={prefs.customerAccess?.displayName}
          libraryChart={props.libraryChart}
          closer={() => {setIsMoveVisible(false); if (props.libraryChartLoader) {props.libraryChartLoader()}}}
        />
        }
      </>
      }
    </div>
  )
}
