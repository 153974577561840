import { useCallback } from "react"
import { AbsoluteTimeSelects } from "./AbsoluteTimeSelects"

import './AbsoluteTimePicker.css'

interface AbsoluteTimePickerProps {
  relativeTime?: string
  startTime?: Date
  endTime?: Date
  timeZone?: string
  absStartSelected: any
  absEndSelected: any
}

export function AbsoluteTimePicker(props: AbsoluteTimePickerProps) {

  const { startTime, absStartSelected, endTime, absEndSelected } = props

  const updateStartTime = useCallback((t: Date) => {
    if (t !== startTime) {
      absStartSelected(t)
    }
  }, [startTime, absStartSelected])

  const updateEndTime = useCallback((t: Date) => {
    if (t !== endTime) {
      absEndSelected(t)
    }
  }, [endTime, absEndSelected])

  return (
    <>
      <hr />
      <div className="abs-time-picker-section-title">
        <span>Start</span>
      </div>
      <div className="light-panel-box abs-time-picker-box">
        <AbsoluteTimeSelects absTime={props.startTime} relativeTime={props.relativeTime} updateAbsTime={updateStartTime} />
      </div>
      <hr />
      <div className="abs-time-picker-section-title">
        <span className="light-bold font-increase-1">End</span>
      </div>
      <div className="light-panel-box abs-time-picker-box">
        <AbsoluteTimeSelects absTime={props.endTime} relativeTime={props.relativeTime} relIndex={1} updateAbsTime={updateEndTime} />
      </div>
      {props.timeZone &&
        <div className="abs-time-picker-tz-label">
          Times are in {props.timeZone}
        </div>}
    </>
  )
}