export interface TimeRanges {
    startVal: string
    startSign: string
    endVal: string
    endSign: string
    isRel: boolean
    startMode: string
    endMode: string
}

const TIMERANGE_PREFIX_OVER = "Over the next"
const TIMERANGE_SUFFIX_PAST = "Ago"
const TIMERANGE_SUFFIX_FUTURE = "From Now"
const TIMERANGE_TO = "to"

// formatRelative returns a prettified relative timestamp.
// Input rel should be a relative timestamp with + appended to the front
// if the timestamp is in the future.
// Returns:
//   - 'Last {rel}' if rel is in the past up until now
//   - '{rel} Ago' if rel is in the past and with an ending other than Nnow
//   - 'now' if rel is now
//   - {rel} from now if rel is in the future
const formatRelative = (rel: string, hasEnd: boolean = false): string => {
  let sign = rel[0]
  if(rel === "now") {
    return rel
  }
  if(sign === "+"){
    return rel.substring(1) + " " + TIMERANGE_SUFFIX_FUTURE
  }
  if (!hasEnd) {
    return "Last " + rel
  }
  return rel + " " + TIMERANGE_SUFFIX_PAST
}

export const formatTimerange = (tr: string): string => {
  const parts = tr.split('-')
  let start, end = ''
  let startsNow = false
  const hasEnd = parts.length !== 1 && parts[1] !== ''
  
  // If start is an absolute date string...
  if (parts[0].length === 10) {
    // Parse as Date and convert to en-US locale
    start = new Date(parseInt(parts[0]) * 1000).toLocaleDateString("en-US", {
      year: "2-digit",
      month: "numeric",
      day: "numeric",
      hour12: false,
      hour: "numeric",
      minute: "2-digit"
    }).replaceAll(',', '')
  } else {
    // Parse as relative timestamp
    start = parts[0]
    startsNow = start === "now"
    start = formatRelative(start, hasEnd)
  }

  // If timerange has an end that is an absolute date string...
  if (hasEnd && parts[1].length === 10) {
    // Parse as Date and convert to en-US locale
    end = new Date(parseInt(parts[1]) * 1000).toLocaleDateString("en-US", {
      year: "2-digit",
      month: "numeric",
      day: "numeric",
      hour12: false,
      hour: "numeric",
      minute: "2-digit"
    }).replaceAll(',', '')
  } else if(hasEnd){
    // Parse as relative timestamp
    end = parts[1]
    if(end[0] === "+" && startsNow) {
      // special case: if ends later and starts now, read as "Over the next {end}"
      return TIMERANGE_PREFIX_OVER + " " + end.substring(1)
    } else {
      end = formatRelative(end, hasEnd)
    }
  }

  if(hasEnd){
    return start + " " + TIMERANGE_TO + " " + end 
  } else {
    return start
  }
}