export function getCustomer() {
    let customer = localStorage.getItem('prefs.customer')
    if (!customer) {
        customer = ''
        localStorage.setItem('prefs.customer', customer)
    }
    return customer
}


export function setCustomerPreference(customer: string) {
    if (!customer) {
        customer = ''
    }
    localStorage.setItem('prefs.customer', customer)
    return customer
}
