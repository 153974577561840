import { useMemo } from 'react'

import './TextArea.css'

interface TextAreaProps {
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>
  onKeyDown?: React.KeyboardEventHandler<HTMLTextAreaElement>
  label?: string
  labelClass?: string
  wrapperClass?: string
  placeholder?: string
  description?: string
  inputId?: string
  autoComplete?: boolean
  autoCapitalize?: string
  autoCorrect?: boolean
  className?: string
  disabled?: boolean
  defaultValue?: string
  id?: string
  inputRef?: any
  footnote?: string
}

export default function TextArea(props: TextAreaProps) {

  const { labelClass, className, wrapperClass, disabled} = props

  const autoComplete = props.autoComplete ? "ON" : "OFF"
  const autoCorrect = props.autoCorrect ? "ON" : "OFF"
  const autoCapitalize = props.autoCapitalize ? props.autoCapitalize : "OFF"

  const classes = useMemo(() => {
    const base = 'input-control'
    if (className) {
      return `${base} ${className}`
    }
    return base
  }, [className])

  const labelClasses = useMemo(() => {
    let base = 'textarea-label'
    if (disabled) {
      base = `${base} subtle`
    }
    if (labelClass) {
      return `${base} ${labelClass}`
    }
    return base
  }, [labelClass, disabled])

  const wrapperClasses = useMemo(() => {
    const base = 'input-control-textarea-container'
    if (wrapperClass) {
      return `${base} ${wrapperClass}`
    }
    return base
  }, [wrapperClass])

  return (
    <div className={wrapperClasses}>
      {props.label && <div className={labelClasses}>
      {props.label}:</div>}
      <div className="wide">
        <textarea
          className={classes}
          id={props.id}
          placeholder={props.placeholder}
          aria-label={props.description}
          onChange={props.onChange}
          onKeyDown={props.onKeyDown}
          autoComplete={autoComplete}
          autoCorrect={autoCorrect}
          autoCapitalize={autoCapitalize}
          disabled={props.disabled}
          defaultValue={props.defaultValue}
          ref={props.inputRef}
        />
        {props.footnote &&
          <>
            <span className="input-control-textarea-footnote">
              {props.footnote}
            </span>
          </>
        }
      </div>
    </div>
  )
}



// <div className="input-control-checkbox-container">
// <input 
//   type="checkbox"
//   className={classes}
//   disabled={props.disabled}
//   id={checkboxId}
//   value={props.value}
//   defaultChecked={props.defaultChecked}
//   onClick={props.onClick}
// />
// {props.label && <label htmlFor={checkboxId} className={labelClasses}>{props.label}</label>}
// </div>