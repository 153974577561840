import { Dictionary } from "./localization";

export const locDictEnUS = {
  // simple words and phrases
  'continue': 'continue',
  'cancel': 'cancel',
  'startOver': 'start over',
  'ok': 'ok',
  'close': 'close', // verb, like close the window
  'workingEllipses': 'working ...',
  'loadingEllipses': 'loading ...',
  'last': 'last', // last as in final
  'saveChanges': 'save changes',
  'requiredField': 'required field',
  'description': 'description',
  'schedule': 'schedule',
  'site': 'site',
  'node': 'node',
  'timePeriod': 'Time Period',
  'to': 'to',
  'cc': 'cc',
  'bcc': 'bcc',
  'edit': 'edit',
  'delete': 'delete',
  'report': 'report',
  'charts': 'charts',
  'climate': 'climate',
  'soil': 'soil',
  'water': 'water',
  'airQuality': 'air quality',
  'co2AndGhg': 'CO2 & Greenhouse Gases',
  'day': 'day',
  'hour': 'hour',
  'minute': 'minute',
  // days of week
  'monday': 'monday',
  'tuesday': 'tuesday',
  'wednesday': 'wednesday',
  'thursday': 'thursday',
  'friday': 'friday',
  'saturday': 'saturday',
  'sunday': 'sunday',
  //
  // sentences and complex phrases
  'noItems': 'no items to display.',
  //
  // reports
  'createReport': 'create report',
  'cadence': 'cadence',
  'editReportSettings': 'edit report settings',
  'newReportSettings': 'new report settings',
  'createNewReport': 'create a new report',
  'generalSettings': 'general settings',
  'scheduleSettings': 'schedule settings',
  'growersReportSettings': 'grower\'s report settings',
  'emailSettings': 'email settings',
  'emailEnabled': 'email enabled',
  'sendReportViaEmail': 'send this report via email',
  'runsDaily': 'runs daily',
  'runsWeekly': 'runs weekly',
  'runsMonthly': 'runs monthly',
  'runManually': 'run manually',
  'seasonToDate': 'season to date',
  'yearToDate': 'calendar year to date',
  'monthToDate': 'current month to date',
  'previousWeekMon': 'previous week (Mon-Sun)',
  'last7Days': 'last 7 days',
  'previousMonth': 'previous month',
  'previousYear': 'previous calendar year',
  'reportType': 'report type',
  'unknownCadence': 'unknown cadence',
  'sectionsToInclude': 'sections to include',
  'chartCount': 'chart count',
  'fileCount': 'file count',
  'summaryPage': 'summary page',
  'separateAddressesWithComma': 'separate multiple addresses with commas.',
  //
  'customerLabel': 'organization',
  //
  // sign in:
  'signInEnterEmail': 'To Sign in to the Grower Portal, enter your email address and press Continue.',
  'signInWeSentEmail': 'We just sent an email to <VAR1>',
  'signInCheckInbox': 'Check your inbox for a message from',
  'signInEnterCode': 'To finish signing in, provide the verification code that we just emailed to you.',
  'signInCodeExpires': 'Verification codes expire after 5 minutes.',
  //
  // Feedback
  'feedbackFormIntroP1': "We're constantly striving to improve our products and services, to bring even more value to Growers.",
  'feedbackFormIntroP2': 'As our valued customer, Agrology welcomes your constructive feedback to help keep us informed about the areas where we can better serve you. 🙏',
  'feedbackFormSubjectHeader': 'What is this feedback regarding?',
  'feedbackFormRemarksHeader': "What's on your mind?",
  'feedbackFormFollowupHeader': 'May we follow-up with you about this?',
  'feedbackFormNoSensitiveInfo': "Please don't include any sensitive information.",
  'feedbackFormConfirmationP1': 'Thanks, your feedback has been delivered to our team!',
  'feedbackFormConfirmationP2': "We'll be in touch soon.",
  'feedbackFormSend': 'Send Feedback',
  'feedbackFormSendError': "Unfortunately an error has prevented us from sending this feedback. We're really sorry about this! Please contact us at support@agrology.ag.",
  //
  'feedbackReasonBugReport': 'Bug Report',
  'feedbackReasonFeatureRequest': 'Feature Request',
  'feedbackReasonServiceIssue': 'Service Issue',
  'feedbackReasonHardwareIssue': 'Hardware / Maintenance Issue',
  'feedbackReasonKudos': 'Kudos to an Agrology Team Member',
  'feedbackReasonSomethingElse': 'Something Else',
} as Dictionary