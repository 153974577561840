import { useMemo } from "react"
import { LibraryChart } from "../../api/types"
import {IQueryConfig, IChartDisplayConfig, DefaultChartDisplayConfig, IChartSettings } from "./IQueryConfig"

import ChartDataManager from "./ChartDataManager"

interface LibraryChartBoxProps {
  chart?: LibraryChart
  siteId?: string
  siteName?: string
  nodeId?: string
  timeRange: string
  setIsLoaded?: React.Dispatch<React.SetStateAction<boolean>>
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>
  libraryChartLoader: any
}

const chartDisplayConfig = (siteName?: string,
  chart?: LibraryChart):IChartDisplayConfig => {
  const cfg = DefaultChartDisplayConfig()
  if (!cfg.title) {
    let title = siteName
    if (chart && chart.description) {
      title += ' - ' + chart.description
    }
    cfg.title = title
  }
  return cfg
}

export default function LibraryChartBox(props: LibraryChartBoxProps) {

  const {chart, nodeId, siteId, siteName } = props

  const chartSettings = useMemo((): IChartSettings => {
    if (!chart) {
      return {} as IChartSettings
    }
    if (!(chart.settings) && (chart.definition)) {
      const qcs = JSON.parse(chart.definition) as IQueryConfig[]
      const cdc = chartDisplayConfig(siteName, chart)
      return {
        queryConfigs: qcs,
        displayConfig: cdc
      }
    }
    const cfg = JSON.parse(chart.settings) as IChartSettings
    return cfg
  }, [chart, siteName])

  const displayConfig = useMemo((): IChartDisplayConfig => {
    if (!chartSettings || !chartSettings.displayConfig) {
      return chartDisplayConfig(siteName, chart)
    }
    const out = { ...chartSettings.displayConfig }
    let title = siteName
    if (chart && chart.description) {
      title += ' - ' + chart.description
    }
    out.title = title
    return out
  }, [chartSettings, siteName, chart])


  const queryConfigs = useMemo((): IQueryConfig[] => {
    const out = [] as IQueryConfig[]
    if (!chartSettings || !chartSettings.queryConfigs || chartSettings.queryConfigs.length === 0) {
      return out
    }
    const cfg = chartSettings.queryConfigs
    for (const c of cfg) {
      if (!c.axis) {
        c.axis = 'y'
      }
      if (!c.style) {
        c.style = 'line'
      }
      out.push(c)
    }
    if (!siteId) {
      return out
    }
    let firstSite = ''
    let firstNode = '' as string | undefined
    for (const i in out) {
      if (!firstSite) {
        firstSite = out[i].site
        out[i].site = siteId
      } else if (out[i].site === firstSite) {
        out[i].site = siteId
      }
      if (!firstNode) {
        firstNode = out[i].node
        out[i].node = nodeId
      } else if (out[i].node === firstNode) {
        out[i].node = nodeId
      }
    }
    return out
  }, [chartSettings, nodeId, siteId])

  return (
    <ChartDataManager
      queryConfigs={queryConfigs}
      containerSource="library"
      timeRange={props.timeRange}
      chartDisplayConfig={displayConfig}
      libraryChart={chart}
      libraryChartLoader={props.libraryChartLoader}
    />
  )
}