import { useMemo } from 'react'

import './TextInput.css'

interface TextInputProps {
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>
  placeholder?: string
  description?: string
  inputId?: string
  autoComplete?: boolean
  autoCapitalize?: string
  autoCorrect?: boolean
  className?: string
  wrapperClass?: string
  label?: string
  labelClass?: string
  disabled?: boolean
  defaultValue?: string
  id?: string
  inputRef?: any
  required?: boolean
}

export default function TextInput(props: TextInputProps) {

  const { wrapperClass, className, labelClass, disabled, required } = props

  const autoComplete = props.autoComplete ? "ON" : "OFF"
  const autoCorrect = props.autoCorrect ? "ON" : "OFF"
  const autoCapitalize = props.autoCapitalize ? props.autoCapitalize : "OFF"

  const classes = useMemo(() => {
    const base = 'input-control'
    if (className) {
      return `${base} ${className}`
    }
    return base
  }, [className])

  const labelClasses = useMemo(() => {
    let base = 'input-text-label'
    if (disabled) {
      base = `${base} subtle`
    } else if (required) {
      base = `${base} required`
    }
    if (labelClass) {
      return `${base} ${labelClass}`
    }
    return base
  }, [labelClass, disabled, required])

  const wrapperClasses = useMemo(() => {
    const base = 'input-control-text-container'
    if (wrapperClass) {
      return `${base} ${wrapperClass}`
    }
    return base
  }, [wrapperClass])

  return (
    <div className={wrapperClasses}>
      {props.label && <div className={labelClasses}>
      {props.label}:</div>}
      <input type="text"
        className={classes}
        id={props.id}
        placeholder={props.placeholder}
        aria-label={props.description}
        onChange={props.onChange}
        onKeyDown={props.onKeyDown}
        autoComplete={autoComplete}
        autoCorrect={autoCorrect}
        autoCapitalize={autoCapitalize}
        disabled={props.disabled}
        defaultValue={props.defaultValue}
        ref={props.inputRef}
      />
    </div>
  )
}
