import axios from 'axios'
import { accessToken } from '../auth/provider';

export const api = axios.create({
  baseURL: `https://api.agrology.ag/v2`
});

api.interceptors.request.use( async (config: any) => {
  const token = await accessToken()
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
}, (error) => {
  return Promise.reject(error)
})

const apiNoAuth = axios.create();

export async function getWithRedirectSupport (path: string) {
  let resp = {} as any
  let redir = ''
  await api
  .get(path)
  .then(r => {
    if ('data' in r && 'code' in r.data && r.data.code === 302 && 'location' in r.data) {
      redir = r.data.location
    } else if (r.data) {
      resp = r.data
    }
  })
  .catch(error => {
    console.log('error fetching :', error)
    resp = error
  })
  if (!redir) {
    return resp
  }
  await apiNoAuth
  .get(redir)
  .then(r => {
    if ('data' in r) {
      resp = r.data
    }
  })
  .catch(error => {
    console.log('error fetching :', error)
    resp = error
  })
  return resp
}
