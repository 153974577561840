
import { useMemo } from 'react'
import { Link } from 'react-router-dom'

import { headerColumn, row, rowCell } from './tables'

import './BasicTable.css'

interface BasicTableProps {
  title?: string
  isLoading?: boolean
  loadingMessage?: string
  noDataMessage?: string
  expandable?: boolean
  cols: headerColumn[]
  rows?: row[]
  className?: string
  containerClassName?: string
  titleClassName?: string
  headerRowClassName?: string
  banded?: boolean
}

let keyID = 0

function nextKey(): number {
  keyID += 1
  return keyID
}

export default function BasicTable(props: BasicTableProps) {

  const { loadingMessage, noDataMessage, rows, banded, title,
    className, containerClassName, headerRowClassName, titleClassName } = props

  const loadingMsg = useMemo(() => {
    if (loadingMessage) {
      return loadingMessage
    }
    return 'Loading Data ...'
  }, [loadingMessage])

  const noDataMsg = useMemo(() => {
    if (noDataMessage) {
      return noDataMessage
    }
    return 'No data to display.'
  }, [noDataMessage])

  const containerClasses = useMemo(() => {
    const base = 'basic-table-container'
    if (containerClassName) {
      return `${base} ${containerClassName}`
    }
    return base
  }, [containerClassName])

  const titleClasses = useMemo(() => {
    const base = 'basic-table-title'
    if (titleClassName) {
      return `${base} ${titleClassName}`
    }
    return base
  }, [titleClassName])

  const tableClasses = useMemo(() => {
    let base = 'basic-table'
    if (banded) {
      base += ' banded'
    } else {
      base += ' lined'
    }
    if (className) {
      return `${base} ${className}`
    }
    return base
  }, [className, banded])

  const headerClasses = useMemo(() => {
    let base = 'basic-table-header-row'
    if (!title) {
      base += ' no-title'
    }
    if (headerRowClassName) {
      return `${base} ${headerRowClassName}`
    }
    return base
  }, [headerRowClassName, title])

  const rowClasses = (row: row)  => {
    const base = 'basic-table-row'
    if (row.className) {
      return `${base} ${row.className}`
    }
    return base
  }

  const cellClasses = (cell: rowCell)  => {
    const base = 'basic-table-cell'
    if (cell.className) {
      return `${base} ${cell.className}`
    }
    return base
  }

    // TODO: ACTUALLY SORT THE ROWS IF NEEDED
    const sortedRows = useMemo(() => {
      return rows
    }, [rows])

  return (
    <div className={containerClasses}>
      {props.title && 
      <div className={titleClasses}>{props.title}</div>
      }
      {props.isLoading && 
        <div className="basic-table-loading-notice">{loadingMsg}</div>
      }
      {!props.isLoading && (!sortedRows || sortedRows.length === 0) &&
        <div className="basic-table-no-data-notice mt-2">{noDataMsg}</div>
      }
      {!props.isLoading && sortedRows && sortedRows.length > 0 &&
        <div className="table-parent">
          <table className={tableClasses}>
            <thead>
              <tr className={headerClasses}>
                {props.cols.map((col) => {
                  return <th key={nextKey()}>{col.text}</th>
                })}
              </tr>
            </thead>
            <tbody>
              {props.rows?.map((row) => {
                return <tr key={nextKey()} className={rowClasses(row)}>
                  {row.cells.map((cell) => {
                    return <td key={nextKey()} className={cellClasses(cell)}>
                      {cell.onClick && !cell.href &&
                        <label className="link-normal" onClick={cell.onClick}>{cell.value}</label>
                      }
                      {!cell.onClick && cell.href &&
                        <Link className="link-normal" to={cell.href}>{cell.value}</Link>
                      }
                      {!cell.onClick && !cell.href &&
                        cell.value
                      }
                    </td>
                  })}
                </tr>
              })}
            </tbody>
          </table>
        </div>
      }
    </div>
  )
}
