import { useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownLeftAndUpRightToCenter, faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons'

import './FullScreenToggle.css'

interface FullScreenToggleProps {
  isFullScreen?: boolean
  onClick?: any
}

const isTouchScreen = navigator.maxTouchPoints && navigator.maxTouchPoints > 0

export default function FullScreenToggle(props: FullScreenToggleProps) {

  const { isFullScreen } = props

  const clicked = () => {
    if (props.onClick) {
      props.onClick()
    }
  }

  const classes = useMemo(() => {
    let base = "fs-toggle"
    if (isFullScreen) {
      base = `${base} ${base}-active`
    }
    if (isTouchScreen) {
      return base + " fs-toggle-touch"
    }
    return base
  }, [isFullScreen])

  return <div className={classes} onClick={clicked}>
    <FontAwesomeIcon
      icon={isFullScreen ? faDownLeftAndUpRightToCenter : faUpRightAndDownLeftFromCenter} />
  </div>

}