import { useContext, useState } from 'react'

import { ReportDefinition } from "../../api/types";
import { growerReportChartScopes, growerReportFilesScopes } from "./selectItems";
import { PrefsContext } from '../../prefs/PrefsContext'
import { GetLocalizedTextItem } from '../../localization/localization';

import CheckBox from "../controls/CheckBox/CheckBox";
import Select from "../controls/Selects/Select";

interface GrowerReportSettingsPanelProps {
  def: ReportDefinition
  aggregateChanged: any
  aggregateFilesChanged: any
  metadataChanged: any
}

const getSections = (def: ReportDefinition): string[] => {
  if (def && def.metadata && 'sections' in def.metadata &&
  def.metadata['sections'] !== '') {
    return def.metadata['sections'].split(',')
  }
  return [] as string[]
}

export default function GrowerReportSettingsPanel(props: GrowerReportSettingsPanelProps) {
  const { def } = props
  const prefs = useContext(PrefsContext)
  const [sections, setSections] = useState(getSections(def) as string[])

  const updateSections = (name: string, e: any) => {
    if (!name || !e) {
      return
    }
    const isChecked = e && e.target && e.target.checked
    const out = [] as string[]
    let found = false
    for (const s of sections) {
      if (s === name) {
        found = true
        if (!isChecked) { // remove 
          continue
        }
      }
      out.push(s)
    }
    if (isChecked && !found) {
      out.push(name)
    }
    setSections(out)
    props.metadataChanged({'sections': out.join(',')})
  }
  
  return (
    <div>
      <div className="modal-edit-report-def-section-header">
        {GetLocalizedTextItem(prefs.language, 'growersReportSettings', 'upperAllFirst')}
      </div>

      <Select
        label={GetLocalizedTextItem(prefs.language, 'chartCount', 'upperAllFirst')}
        labelClass="width-85"
        className="wide"
        wrapperClass="mb-2"
        items={growerReportChartScopes}
        defaultValue={def.aggregate}
        onChange={props.aggregateChanged}
      />

      <Select
        label={GetLocalizedTextItem(prefs.language, 'fileCount', 'upperAllFirst')}
        labelClass="width-85"
        className="wide"
        wrapperClass="mb-2"
        items={growerReportFilesScopes}
        defaultValue={def.aggregateFiles || 'site'}
        onChange={props.aggregateFilesChanged}
      />

      <div className="ms-3 subsection-header">
        {GetLocalizedTextItem(prefs.language, 'sectionsToInclude', 'upperAllFirst')}:
      </div>
      <div className="ms-4">
        <CheckBox
          label={GetLocalizedTextItem(prefs.language, 'summaryPage', 'upperAllFirst')}
          labelClass="ps-2"
          defaultChecked={sections.includes('summary')}
          wrapperClass="width-fc"
          onClick={(e: any) => { updateSections('summary', e) }}
        />
        <CheckBox
          label={GetLocalizedTextItem(prefs.language, 'climate', 'upperAllFirst') + ' ' + GetLocalizedTextItem(prefs.language, 'charts', 'upperAllFirst')}
          labelClass="ps-2"
          defaultChecked={sections.includes('climateCharts')}
          wrapperClass="width-fc"
          onClick={(e: any) => { updateSections('climateCharts', e) }}
        />
        <CheckBox
          label={GetLocalizedTextItem(prefs.language, 'soil', 'upperAllFirst') + ' ' + GetLocalizedTextItem(prefs.language, 'charts', 'upperAllFirst')}
          labelClass="ps-2"
          defaultChecked={sections.includes('soilCharts')}
          wrapperClass="width-fc"
          onClick={(e: any) => { updateSections('soilCharts', e) }}
        />
        <CheckBox
          label={GetLocalizedTextItem(prefs.language, 'water', 'upperAllFirst') + ' ' + GetLocalizedTextItem(prefs.language, 'charts', 'upperAllFirst')}
          labelClass="ps-2"
          defaultChecked={sections.includes('waterCharts')}
          wrapperClass="width-fc"
          onClick={(e: any) => { updateSections('waterCharts', e) }}
        />
        <CheckBox
          label={GetLocalizedTextItem(prefs.language, 'airQuality', 'upperAllFirst') + ' ' + GetLocalizedTextItem(prefs.language, 'charts', 'upperAllFirst')}
          labelClass="ps-2"
          defaultChecked={sections.includes('aqCharts')}
          wrapperClass="width-fc"
          onClick={(e: any) => { updateSections('aqCharts', e) }}
        />
        <CheckBox
          label={GetLocalizedTextItem(prefs.language, 'co2AndGhg', 'upperAllFirst') + ' ' + GetLocalizedTextItem(prefs.language, 'charts', 'upperAllFirst')}
          labelClass="ps-2"
          defaultChecked={sections.includes('ghgCharts')}
          wrapperClass="width-fc"
          onClick={(e: any) => { updateSections('ghgCharts', e) }}
        />
      </div>
    </div>
  )
}