
import { GetLocalizedTextItem } from '../localization/localization'

const lang = 'en-us'

interface offset {
  input: number
  hour: number
  minute: number
  seconds: number
  formatted: string
  ampm: string
}

export function formatOffset(input: number, includeSeconds = false): offset {
  const out = {input: input} as offset
  if (input === 0) {
    out.formatted = 'Midnight'
    return out
  }
  out.ampm = 'AM'
  out.hour = Math.floor(input / 3600)
  if (out.hour === 12) {
    out.ampm = 'PM'
  } else if (out.hour === 0) {
    out.hour = 12
  } else if (out.hour >= 13) {
    out.hour = out.hour - 12
    out.ampm = 'PM'
  }

  if (input < 86400) {
    const minute = Math.floor((input - (out.hour * 3600)) / 60)
    let smins = minute < 10 ? `:0${minute}` : `:${minute}`
    let secs = Math.floor((input % 60))
    let ssecs = `:${secs}`
    if (secs === 0) {
      ssecs = ''
    } else if (secs < 10) {
      ssecs = `:0${secs}`
    }
    out.formatted = includeSeconds ? `${out.hour}${smins}${ssecs} ${out.ampm}` : `${out.hour}${smins} ${out.ampm}`
  }

  // todo: weekly and monthly

  return out
}

export const hourOffsets = [
  {
    formatted: '12 AM',
    hour: 0
  },
  {
    formatted: '1 AM',
    hour: 1
  },
  {
    formatted: '2 AM',
    hour: 2
  },
  {
    formatted: '3 AM',
    hour: 3
  },
  {
    formatted: '4 AM',
    hour: 4
  },
  {
    formatted: '5 AM',
    hour: 5
  },
  {
    formatted: '6 AM',
    hour: 6
  },
  {
    formatted: '7 AM',
    hour: 7
  },
  {
    formatted: '8 AM',
    hour: 8
  },
  {
    formatted: '9 AM',
    hour: 9
  },
  {
    formatted: '10 AM',
    hour: 10
  },
  {
    formatted: '11 AM',
    hour: 11
  },
  {
    formatted: '12 PM',
    hour: 12
  },
  {
    formatted: '1 PM',
    hour: 13
  },
  {
    formatted: '2 PM',
    hour: 14
  },
  {
    formatted: '3 PM',
    hour: 15
  },
  {
    formatted: '4 PM',
    hour: 16
  },
  {
    formatted: '5 PM',
    hour: 17
  },
  {
    formatted: '6 PM',
    hour: 18
  },
  {
    formatted: '7 PM',
    hour: 19
  },
  {
    formatted: '8 PM',
    hour: 20
  },
  {
    formatted: '9 PM',
    hour: 21
  },
  {
    formatted: '10 PM',
    hour: 22
  },
  {
    formatted: '11 PM',
    hour: 23
  },
] as offset[]

export const minuteOffsets = [
  {
    formatted: ':00',
    minute: 0
  },
  {
    formatted: ':05',
    minute: 5
  },
  {
    formatted: ':10',
    minute: 10
  },
  {
    formatted: ':15',
    minute: 15
  },
  {
    formatted: ':20',
    minute: 20
  },
  {
    formatted: ':25',
    minute: 25
  },
  {
    formatted: ':30',
    minute: 30
  },
  {
    formatted: ':35',
    minute: 35
  },
  {
    formatted: ':40',
    minute: 40
  },
  {
    formatted: ':45',
    minute: 45
  },
  {
    formatted: ':50',
    minute: 50
  },
  {
    formatted: ':55',
    minute: 55
  },
] as offset[]

export const dayOfMonthOffsets = [
  {
    formatted: '1',
    input: 1
  },
  {
    formatted: '2',
    input: 2
  },
  {
    formatted: '3',
    input: 3
  },
  {
    formatted: '4',
    input: 4
  },
  {
    formatted: '5',
    input: 5
  },
  {
    formatted: '6',
    input: 6
  },
  {
    formatted: '7',
    input: 7
  },
  {
    formatted: '8',
    input: 8
  },
  {
    formatted: '9',
    input: 9
  },
  {
    formatted: '10',
    input: 10
  },
  {
    formatted: '11',
    input: 11
  },
  {
    formatted: '12',
    input: 12
  },
  {
    formatted: '13',
    input: 13
  },
  {
    formatted: '14',
    input: 14
  },
  {
    formatted: '15',
    input: 15
  },
  {
    formatted: '16',
    input: 16
  },
  {
    formatted: '17',
    input: 17
  },
  {
    formatted: '18',
    input: 18
  },
  {
    formatted: '19',
    input: 19
  },
  {
    formatted: '20',
    input: 20
  },
  {
    formatted: '21',
    input: 21
  },
  {
    formatted: '22',
    input: 22
  },
  {
    formatted: '23',
    input: 23
  },
  {
    formatted: '24',
    input: 24
  },
  {
    formatted: '25',
    input: 25
  },
  {
    formatted: '26',
    input: 26
  },
  {
    formatted: '27',
    input: 27
  },
  {
    formatted: '28',
    input: 28
  },
  {
    formatted: '29',
    input: 29
  },
  {
    formatted: '30',
    input: 30
  },
  {
    formatted: '31',
    input: 31
  },
  {
    formatted: GetLocalizedTextItem(lang, 'last', 'upperAllFirst'),
    input: 99
  },
] as offset[]

export const dayOfWeekOffsets = [
  {
    formatted: GetLocalizedTextItem(lang, 'monday', 'upperAllFirst'),
    input: 1
  },
  {
    formatted: GetLocalizedTextItem(lang, 'tuesday', 'upperAllFirst'),
    input: 2
  },
  {
    formatted: GetLocalizedTextItem(lang, 'wednesday', 'upperAllFirst'),
    input: 3
  },
  {
    formatted: GetLocalizedTextItem(lang, 'thursday', 'upperAllFirst'),
    input: 4
  },
  {
    formatted: GetLocalizedTextItem(lang, 'friday', 'upperAllFirst'),
    input: 5
  },
  {
    formatted: GetLocalizedTextItem(lang, 'saturday', 'upperAllFirst'),
    input: 6
  },
  {
    formatted: GetLocalizedTextItem(lang, 'sunday', 'upperAllFirst'),
    input: 7
  },
] as offset[]
