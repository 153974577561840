import { useState } from 'react'

import { useAuth } from "../../auth/provider"
import { VERSION } from '../../app/app'

import Dot from '../controls/Dot/Dot'
import ModalUserInfo from '../auth/ModalUserInfo'
import ModalCredits from '../credits/ModalCredits'

import './AppFooter.css'

function Copyright() {
  return (
    <span>
      © {new Date().getFullYear()} {process.env.REACT_APP_appShortName} PBC
    </span>
  )
}

function Username(props: any) {
  return (
    <>
    <Dot />
      <span>
        <button className="username-link" onClick={props.opener}>
          {props.email}
        </button>
      </span>
    </>
  )
}

function AppFooter() {
  const { username } = useAuth()
  const [isUserInfoModalVisible, setUserInfoModalVisibility] = useState(false)
  const [isCreditModalVisible, setIsCreditModalVisible] = useState(false)

  return (
    <>
      <div className="footer">
        <Copyright />
        {username && <Username
          email={username}
          opener={() => {setUserInfoModalVisibility(true)}}
        />}
        <Dot />
        <div
          className="footer-app-version" 
          onClick={() => {setIsCreditModalVisible(true)}}
        >
          v{ VERSION }
        </div>
      </div>
      {isUserInfoModalVisible && username &&
        <ModalUserInfo
          closer={() => {setUserInfoModalVisibility(false)}}
        />
      }
      {isCreditModalVisible &&
        <ModalCredits closer={() => {setIsCreditModalVisible(false)}} />
      }
    </>
  )
}

export default AppFooter
