import { LibraryChart } from "../../api/types"
import LibraryList from "./LibraryList"
import TimePicker from "../TimePicker/TimePicker"
import SiteAndNodeSelector from "../fleet/SiteAndNodeSelector"

import './LibraryConfig.css'

interface LibraryConfigProps {
  chartSelectedFunc: any
  setTimeRangeFunc: any
  timeRange?: string
  selectedChartID?: string
  charts?: LibraryChart[]
  onSiteChange?: any
  onNodeChange?: any
  nodeId?: string
  siteId?: string
  isLoading?: boolean
  isLoaded?: boolean
}

export default function LibraryConfig(props: LibraryConfigProps) {

  return (
    <div className="controls-box chart-library-controls me-2 p-2">
      <div style={{marginLeft: '-3px', marginTop: '-3px'}}>
        <TimePicker
          timeChangeFunc={props.setTimeRangeFunc}
          timeRange={props.timeRange}
        />
        <hr />
        <SiteAndNodeSelector
          className="chart-library-select"
          onNodeChange={props.onNodeChange}
          onSiteChange={props.onSiteChange}
          nodeId={props.nodeId}
          siteId={props.siteId}
          hideLabels={true}
          hideSelectASite={true}
        />
      </div>
      <hr />
      <LibraryList
        chartSelectedFunc={props.chartSelectedFunc}
        selectedChartID={props.selectedChartID}
        charts={props.charts}
        siteId={props.siteId}
        isLoading={props.isLoading}
        isLoaded={props.isLoaded}
      />
    </div>
  )
}