import { locDictEnUS } from './english'
import { locDictPigLatin } from './piglatin'

export interface Dictionary {
  [key: string]: string
}

export interface DictionaryLookup {
  [key: string]: Dictionary
}

export const Lookup = {
  'en-us': locDictEnUS,
  'en': locDictEnUS,
  'piglatin': locDictPigLatin
} as DictionaryLookup

const defaultLang = 'en-us'
const defaultDictionary = locDictEnUS

function withCasing(input: string, casing: string): string {
  if (!input) {
    return input
  }
  if (casing === 'upper') {
    return input.toUpperCase()
  }
  if (casing === 'lower') {
    return input.toLowerCase()
  }
  if (casing === 'upperFirst') {
    return input.charAt(0).toLocaleUpperCase() + input.substring(1)
  }
  if (casing === 'upperAllFirst') {
    let wasLastSpace = true
    for (let i = 0; i < input.length; i+=1) {
      let ch = input.charAt(i)
      const wls = ['\n', ' ', '\t'].includes(ch)
      if (wasLastSpace && !wls) {
        const sep = i === 0 ? '' : ' '
        input = input.replace(`${sep}${ch}`, `${sep}${ch.toUpperCase()}`)
        wasLastSpace = false
      } else if (wls && !wasLastSpace) {
        wasLastSpace = true
      } else {
        wasLastSpace = false
      }
    }
    input = input.replaceAll(' On ', ' on ').replaceAll(' Of ', ' of ').replaceAll(' The ', ' the ').replaceAll(' As ', ' as ').replaceAll(' To ', ' to ').replaceAll(' A ', ' a ')
  }
  return input
}

export function GetLocalizedTextItem(lang: string, key: string,
  casing: string = 'normal'): string {
  if (!lang || !(lang in Lookup)) {
    lang = defaultLang
  }
  const d = Lookup[lang]
  if (!(key in d)) {
    if (lang !== defaultLang) {
      if (key in defaultDictionary) {
        return withCasing(defaultDictionary[key], casing)
      }
    }
    return ''
  }
  return withCasing(d[key], casing)
}

export function Interpolate(input: string, vars?: string[]):string {
  if (!vars || vars.length === 0) {
    return input
  }
  for (const i in vars) {
    const j = Number(i) + 1
    input = input.replaceAll(`<VAR${j}>`, vars[i])
  }
  return input
}
