import { useMemo, useState, useContext } from "react"

import { ReportDefinition } from "../../api/types"
import { PrefsContext } from '../../prefs/PrefsContext'
import { GetLocalizedTextItem } from "../../localization/localization"

import { supportedReportsLookup, unknownReport } from "./supportedReports"

import ButtonBasic from "../controls/Buttons/ButtonBasic"
import BasicPanel from "../controls/Panels/basic/BasicPanel"
import { ModalEditReportDefinition } from "./ModalEditReportDefinition"
import { getScheduleDescription } from "./schedule"

interface ReportDefinitionSettingsProps {
  def: ReportDefinition
  requestReloadFunc: any
}

export default function ReportDefinitionSettings(props: ReportDefinitionSettingsProps) {

  const [isConfigEditVisible, setIsConfigEditVisible] = useState(false)
  const { def } = props
  const { language, isCustomerEditor } = useContext(PrefsContext)
  
  const entry = useMemo(() => {
    if (!def || !def.templateID) {
      return unknownReport
    }
    if (def.templateID in supportedReportsLookup) {
      return supportedReportsLookup[def.templateID]
    }
    return unknownReport
  }, [def])

  const sch = useMemo(() => {
    return getScheduleDescription(def)
  }, [def])

  return (
    <>
      <BasicPanel className="report-definition-detail-config filled me-4">
      <div className="report-definition-detail-config-data">
        <div className="report-definition-detail-row">
          <span className="me-2"><b>{GetLocalizedTextItem(language, 'reportType', 'upperAllFirst')}:</b></span>
          <span>{entry.title}</span>
        </div>
        <div className="report-definition-detail-row">
          <span className="me-2"><b>{GetLocalizedTextItem(language, 'schedule', 'upperAllFirst')}:</b></span>
          <span>{sch}</span>
        </div>
        <div className="report-definition-detail-row">
          <span className="me-2"><b>{GetLocalizedTextItem(language, 'emailEnabled', 'upperAllFirst')}:</b></span>
          <span>{def.emailEnabled ? '✓' : '-'} </span>
        </div>
        {def.emailEnabled && def.recipients && <div className="report-definition-detail-row">
          <span className="me-2"><b>{GetLocalizedTextItem(language, 'to', 'upperAllFirst')}:</b></span>
          <span>{def.recipients.join(', ')}</span>
        </div>}
        {def.emailEnabled && def.cc && <div className="report-definition-detail-row">
          <span className="me-2"><b>{GetLocalizedTextItem(language, 'cc', 'upperAllFirst')}:</b></span>
          <span>{def.cc.join(', ')}</span>
        </div>}
        {def.emailEnabled && def.bcc && <div className="report-definition-detail-row">
          <span className="me-2"><b>{GetLocalizedTextItem(language, 'bcc', 'upperAllFirst')}:</b></span>
          <span>{def.bcc.join(', ')}</span>
        </div>}
      </div>
      {isCustomerEditor && <div className="report-definition-detail-config-action">
        <ButtonBasic label={GetLocalizedTextItem(language, 'editReportSettings', 'upperAllFirst')} className="px-4" onClick={() => {setIsConfigEditVisible(true)}} />
      </div>}
    </BasicPanel>
    {isConfigEditVisible &&
      <ModalEditReportDefinition
        closer={() => {setIsConfigEditVisible(false)}}
        def={def}
        requestReloadFunc={props.requestReloadFunc}
      />
    }
  </>
  )
}