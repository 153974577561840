import { useMemo } from "react"

import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import './PanelBanner.css'

interface PanelBannerProps {
  message: string
  status: string
  className?: string
  showIcon?: boolean
}

export default function PanelBanner(props: PanelBannerProps) {

  const { message, status, className, showIcon } = props

  const classes = useMemo(() => {
    let base = 'panel-banner'
    if (status === 'ok' || status === 'success') {
      base += ' panel-banner-ok'
    } else if (status === 'error' || status === 'failure') {
      base += ' panel-banner-failure'
    } else if (status === 'warning') {
      base += ' panel-banner-warning'
    }
    if (className) {
      return `${base} ${className}`
    }
    return base
  }, [className, status])

  const icon = useMemo(() => {
    if (!showIcon) {
      return undefined
    }
    if (status === 'ok') {
      return faCheck
    }
    if (status === 'failure') {
      return faTimes
    }
    return undefined
  }, [showIcon, status])

  return (
    <div className={classes}>
      {props.showIcon && icon &&
        <div className="panel-banner-icon">
          <FontAwesomeIcon icon={icon} />
        </div>
      }
      <div className="pane-banner-message">
        { message }
      </div>
    </div>
  )
}