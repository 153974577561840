
export interface timeZoneEntry {
    dbName: string
    shortName: string
    usesDST: boolean
}

interface timeZoneLookup {
    [dbName: string]: timeZoneEntry
}

export const timezoneFromDbName = (input: string) => {
    if (input in timeZoneEntries) {
        return timeZoneEntries[input]
    }
    return timeZoneEntries["Etc/UTC"]
}

export const getTimeZone = () => {
    return timezoneFromDbName(Intl.DateTimeFormat().resolvedOptions().timeZone)
}

const timeZoneEntries: timeZoneLookup = {
    "America/Denver": {
        dbName: "America/Denver",
        shortName: "US Mountain",
        usesDST: true
    },
    "America/Los_Angeles": {
        dbName: "America/Los_Angeles",
        shortName: "US Pacific",
        usesDST: true
    },
    "America/Boise": {
        dbName: "America/Boise",
        shortName: "US Mountain",
        usesDST: true
    },
    "America/Chicago": {
        dbName: "America/Chicago",
        shortName: "US Central",
        usesDST: true
    },
    "America/Detroit": {
        dbName: "America/Detroit",
        shortName: "US Eastern",
        usesDST: true
    },
    "America/New_York": {
        dbName: "America/New_York",
        shortName: "US Eastern",
        usesDST: true
    },
    "America/Phoenix": {
        dbName: "America/Phoenix",
        shortName: "US Mountain",
        usesDST: false
    },
    "Pacific/Honolulu": {
        dbName: "Pacific/Honolulu",
        shortName: "US Hawaii",
        usesDST: false
    },
    "Asia/Baghdad": {
        dbName: "Asia/Baghdad",
        shortName: "AST",
        usesDST: false
    },
    "Etc/UTC": {
        dbName: "Etc/UTC",
        shortName: "UTC",
        usesDST: false
    }   
}


