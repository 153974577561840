import { useMemo, useState } from "react"

import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons"
import { faX } from "@fortawesome/free-solid-svg-icons"

import './WhatsThis.css'

const isTouchScreen = navigator.maxTouchPoints && navigator.maxTouchPoints > 0

interface WhatsThisProps {
  icon?: IconProp
  description: string
  className?: string
  useHover?: boolean
}

export default function WhatsThis(props: WhatsThisProps) {

  const [isOpen, setIsOpen] = useState(false)
  const { className } = props
  const useHover = props.useHover && !isTouchScreen

  const classes = useMemo(() => {
    const base = "whats-this-container"
    if (className) {
      return `${base} ${className}`
    }
    return base
  }, [className])

  return <div className={classes}>
    <FontAwesomeIcon
      className="whats-this-icon" icon={props.icon || faCircleQuestion}
      onClick={() => {setIsOpen(!isOpen)}}
    />
    {useHover && <div className="whats-this-tooltip whats-this-tooltip-hover">
      <div>{props.description}</div>
    </div>}
    {!useHover && isOpen && <div className="whats-this-tooltip">
      <div className="whats-this-tooltip-closer">
        <FontAwesomeIcon
          className="whats-this-tooltip-closer-icon"
          icon={faX}
          onClick={() => {setIsOpen(false)}}
        />
      </div>
      <div>{props.description}</div>
    </div>}
  </div>
}
