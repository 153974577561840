import React, { useEffect, useState } from "react"
import { CustomerAccess, UserAccess } from "../api/types"
import { getCustomer, setCustomerPreference } from "./customer"
import { loadTheme, setThemePreference } from "./theme"
import { getTimeZone, timeZoneEntry } from "./timezones"
import { getUnits, setUnitsPreference } from "./units"
import { IsCustomerEditor } from "../access/access"
import { useParams } from "react-router-dom"

export interface IPrefsContext {
    tz: timeZoneEntry
    theme: string
    setThemeFunc: any
    units: string
    setUnitsFunc: any
    customer: string
    setCustomerFunc: any
    customerAccess: CustomerAccess | undefined
    allAccess: UserAccess | undefined
    setAllAccessFunc: any
    language: string
    setLanguageFunc: any
    isCustomerEditor: boolean
    hasCustomer: any
}

export function defaultPrefs() {
    return {
        tz: getTimeZone(),
        theme: loadTheme(),
        setThemeFunc: null,
        units: getUnits(),
        setUnitsFunc: null,
        customer: getCustomer(),
        allAccess: undefined,
        setCustomerFunc: null,
        hasCustomer: null,
        customerAccess: undefined,
        setAllAccessFunc: null,
        language: "en-us",
        isCustomerEditor: false
    } as IPrefsContext
}

export const PrefsContext = React.createContext<IPrefsContext>(defaultPrefs())

interface IPrefsProviderProps {
    children: React.ReactNode;
}

export const PrefsProvider = ({ children }: IPrefsProviderProps) => {

    const value = defaultPrefs() as IPrefsContext

    const [theme, setTheme] = useState(value.theme)
    const [units, setUnits] = useState(value.units)
    const [cust, setCustomer] = useState(value.customer)

    const [custAccess, setCustomerAccess] = useState(value.customerAccess)
    const [isCustomerEditor, setIsCustomerEditor] = useState(false)
    const [allAccess, setAllAccess] = useState<UserAccess>({} as UserAccess)

    value.units = units
    value.setUnitsFunc = (units: string) => {
        units = setUnitsPreference(units)
        setUnits(units)
    }

    value.theme = theme
    value.setThemeFunc = (theme: string) => {
        theme = setThemePreference(theme)
        setTheme(theme)
    }

    const {customerId} = useParams()
    useEffect(() => {
      if (customerId) {
        setCustomerPreference(customerId)
        setCustomer(customerId)
      }
    }, [customerId, setCustomer])

    useEffect(() => {
      if (!allAccess.siteAccess || allAccess.siteAccess.length === 0) {
        return
      }
      if (!cust) {
        const c = setCustomerPreference(allAccess.siteAccess[0].customer)
        setCustomer(c)
        setIsCustomerEditor(IsCustomerEditor(allAccess.siteAccess[0]))
        return
      }
      for (const access of allAccess.siteAccess) {
        if (access.customer === cust) {
          setCustomerAccess(access)
          setIsCustomerEditor(IsCustomerEditor(access))
          return
        }
      }
      const c = setCustomerPreference(allAccess.siteAccess[0].customer)
      setCustomer(c)
      setIsCustomerEditor(IsCustomerEditor(allAccess.siteAccess[0]))
    }, [allAccess, cust, setCustomer, setCustomerAccess])

    value.customer = cust
    value.setCustomerFunc = (cust: string) => {
      setCustomerPreference(cust)
      setCustomer(cust)
    }

    value.allAccess = allAccess
    value.setAllAccessFunc = (access: UserAccess) => {
        setAllAccess(access)
    }

    value.hasCustomer = (customer: string) => {
      if (!customer || !value.allAccess || !value.allAccess.siteAccess ||
        value.allAccess.siteAccess.length === 0) {
        return false
      }
      for (const access of allAccess.siteAccess) {
        if (access.customer === customer) {
          return true
        }
      }
      return false
    }

    value.customerAccess = custAccess
    value.isCustomerEditor = isCustomerEditor

    return (
      <PrefsContext.Provider value={value}>
        {children}
      </PrefsContext.Provider>
    )
}
