import './Carats.css'

export function CaratDown() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="carat" viewBox="0 0 16 16" height="12px" width="10px">
            <path fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" />
        </svg>
    )
}

export function CaratLeft() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="carat carat-left" viewBox="0 0 16 16" height="12px" width="10px">
            <path fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m8 4 L15 9 L8 15" />
        </svg>
    )
}

export function CaratUp() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="carat carat-left" viewBox="0 0 16 16" height="12px" width="10px">
            <path fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m3 10 L9 4 L15 10" />
        </svg>
    )
}