import { useContext, useMemo, useState } from "react";

import { CustomerAccess, ReportDefinition } from "../../api/types";
import { PrefsContext } from "../../prefs/PrefsContext";
import { GetLocalizedTextItem } from "../../localization/localization";
import { GrowersReportTemplateID } from "./supportedReports";


import { ReportDefinitionDetail } from "./ReportDefinitionDetail";
import ButtonBasic from "../controls/Buttons/ButtonBasic";
import ListBoxNav, { ListBoxNavElement } from "../controls/ListBoxNav/ListBoxNav";
import { ModalEditReportDefinition } from "./ModalEditReportDefinition";

import './ReportDefinitionsList.css'

function LoadingBody() {

  const { language } = useContext(PrefsContext)

  return (
    <div className="loading-or-no-reports-body loading-text">
      <br />
      <br />
      <br />
      { GetLocalizedTextItem(language, 'loadingEllipses', 'upperAllFirst')}
      <br />
      <br />
      <br />
    </div>
  )
}

interface NoReportsBodyProps {
  customerName?: string
  onClick?: any
}

function NoReportsBody(props: NoReportsBodyProps) {

  const { language, isCustomerEditor } = useContext(PrefsContext)
  const { customerName } = props
  const custName = useMemo(() => {
    if (customerName) {
      return customerName
    }
    return "This Organization"
  }, [customerName])

  const onClick = () => {
    if (props.onClick) {
      props.onClick()
    }
  }

  return (
    <div className="loading-or-no-reports-body">
      <b>{custName}</b> does not have any Reports set up yet.
      <br />
      <br />
      <br />
      <br />
      {isCustomerEditor && <ButtonBasic label={GetLocalizedTextItem(language, 'createNewReport', 'upperAllFirst')} onClick={onClick} />}
    </div>
  )
}

interface ReportDefinitionsProps {
  access: CustomerAccess | undefined
  defs: ReportDefinition[]
  isLoading?: boolean
  requestReloadFunc: any
  selectedReportId?: string
  selectedRunId?: string
}

export default function ReportDefinitionsList(props: ReportDefinitionsProps) {

  const { defs, requestReloadFunc, selectedReportId, selectedRunId } = props
  const [isConfigCreateVisible, setIsConfigCreateVisible] = useState(false)
  const { language, isCustomerEditor } = useContext(PrefsContext)

  const classes = useMemo(() => {
    const baseClasses = `report-definitions-list`
    let fullClasses = baseClasses
    return fullClasses
  }, [])

  const items = useMemo(() => {
    const out = [] as ListBoxNavElement[]
    for (const def of defs) {
      out.push(
        {
          id: def.id,
          label: def.description || '',
          content: <ReportDefinitionDetail
                    def={def} 
                    requestReloadFunc={requestReloadFunc}
                    runId={def.id === selectedReportId ? selectedRunId : undefined}
                  />
        } as ListBoxNavElement
      )
    }

    return out
  }, [defs, requestReloadFunc, selectedReportId, selectedRunId])

  return (
    <>
      <ListBoxNav
        className={classes}
        elements={items}
        noElementsContent={props.isLoading ? <LoadingBody /> : <NoReportsBody customerName={props.access?.displayName} onClick={() => { setIsConfigCreateVisible(true) }} />}
        title={'Reports for ' + (props.access?.displayName || 'this Organization')}
        labelWhenElementOpen="Back to Reports List"
        openLabelHref="/reports"
        selectedElement={selectedReportId}
      />
      { isCustomerEditor && items && items.length > 0 && !selectedReportId &&
        <div className="mt-3 centered">
          <ButtonBasic
            label={GetLocalizedTextItem(language, 'createNewReport', 'upperAllFirst')}
            onClick={() => { setIsConfigCreateVisible(true) }}
          />
        </div>
      }
      { isConfigCreateVisible &&
        <ModalEditReportDefinition
          closer={() => {setIsConfigCreateVisible(false)}}
          def={{
            entryType: 'manual',
            templateID: GrowersReportTemplateID,
            aggregate: 'site',
            timeRange: 'l7d',
            metadata: {
              'sections': 'summary,climateCharts,soilCharts,waterCharts,aqCharts,ghgCharts'
            } as { [key: string]: string }
          } as ReportDefinition}
          requestReloadFunc={props.requestReloadFunc}
        />
      }
    </>
  )
}
