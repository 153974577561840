import { useMemo, useState } from "react"
import { CaratDown, CaratUp } from "../../svg/Carats"

import './ExpandingPanel.css'

interface ExpandingPanelProps {
  defaultIsOpen?: boolean
  title: string
  className?: string
  closedClassName?: string
  openClassName?: string
  children?: any
}

export default function ExpandingPanel(props: ExpandingPanelProps) {

  const { defaultIsOpen, className, openClassName, closedClassName } = props
  const [isOpen, setIsOpen] = useState(defaultIsOpen)

  const classes = useMemo(() => {
    let base = 'expand-panel'
    if (className) {
      base += ' ' + className
    }
    if (isOpen) {
      if (openClassName) {
        base += ' ' + openClassName
      }
      return `${base} expand-panel-open`
    }
    if (closedClassName) {
      base += ' ' + closedClassName
    }
    return `${base} expand-panel-closed`
  }, [isOpen, className, openClassName, closedClassName])

  return (
    <div className={classes}>
      <div
        className="expand-panel-header"
        onClick={() => {setIsOpen(!isOpen)}}
      >
        <div className="expand-panel-title">{props.title}</div>
          {isOpen && <CaratUp />}
          {!isOpen && <CaratDown />}
      </div>
      {isOpen && 
        <>
          <hr />
          {props.children}
        </>
      }
    </div>
  )
}