import { GetLocalizedTextItem } from '../../localization/localization'
import { SelectItem } from '../controls/Selects/Select'

const lang = 'en-us'

export const reportCadences = [
  {value: 'manual', label: GetLocalizedTextItem(lang, 'runManually', 'upperAllFirst' ) },
  {value: 'daily', label: GetLocalizedTextItem(lang, 'runsDaily', 'upperAllFirst' ) },
  {value: 'weekly', label: GetLocalizedTextItem(lang, 'runsWeekly', 'upperAllFirst' ) },
  {value: 'monthly', label: GetLocalizedTextItem(lang, 'runsMonthly', 'upperAllFirst' ) },
] as SelectItem[]

export const reportRanges = [
  {value: 'snl', label: GetLocalizedTextItem(lang, 'seasonToDate', 'upperAllFirst' ) },
  {value: 'ytd', label: GetLocalizedTextItem(lang, 'yearToDate', 'upperAllFirst' ) },
  {value: 'mtd',  label: GetLocalizedTextItem(lang, 'monthToDate', 'upperAllFirst' ) },
  {value: 'lw', label: GetLocalizedTextItem(lang, 'previousWeekMon', 'upperAllFirst' ) },
  {value: 'l7d', label: GetLocalizedTextItem(lang, 'last7Days', 'upperAllFirst' ) },
  {value: 'lm', label: GetLocalizedTextItem(lang, 'previousMonth', 'upperAllFirst' ) },
  {value: 'ly', label: GetLocalizedTextItem(lang, 'previousYear', 'upperAllFirst' ) },
] as SelectItem[]

export const growerReportAggregations = [
  {value: 'customer', label: GetLocalizedTextItem(lang, 'customerLabel', 'upperAllFirst' ) },
] as SelectItem[]

export const growerReportChartScopes = [
  {value: "customer", label: "Fewer Charts with More Lines" },
  {value: "site", label: "Normal" },
  {value: "node", label: "More Charts with Fewer Lines" }
] as SelectItem[]

export const growerReportFilesScopes = [
  {value: "customer", label: "All Charts in One File" },
  {value: "site", label: "One File per-Site" },
  {value: "node", label: "One File per-Node" }
] as SelectItem[]
