import { useContext } from 'react'
import { PrefsContext } from '../../prefs/PrefsContext'
import { CheckMenuItem } from './CheckMenuItem'

import './Menu.css'

interface SettingsMenuProps {
  closer: any,
  posX: number
}

export function SettingsMenu(props: SettingsMenuProps) {

  const prefs = useContext(PrefsContext)

  const setOSThemeMode = () => {
    if (prefs.setThemeFunc) {
      prefs.setThemeFunc('os')
    }
    if (props.closer) {
      props.closer()
    }
  }

  const setDarkMode = () => {
    if (prefs.setThemeFunc) {
      prefs.setThemeFunc('darkMode')
    }
    if (props.closer) {
      props.closer()
    }
  }

  const setLightMode = () => {
    if (prefs.setThemeFunc) {
      prefs.setThemeFunc('lightMode')
    }
    if (props.closer) {
      props.closer()
    }
  }

  const setHighContrastMode = () => {
    if (prefs.setThemeFunc) {
      prefs.setThemeFunc('highContrastMode')
    }
    if (props.closer) {
      props.closer()
    }
  }

  const setMetric = () => {
    if (prefs.setUnitsFunc) {
      prefs.setUnitsFunc('metric')
    }
    if (props.closer) {
      props.closer()
    }
  }

  const setImperial = () => {
    if (prefs.setUnitsFunc) {
      prefs.setUnitsFunc('imperial')
    }
    if (props.closer) {
      props.closer()
    }
  }

  const setSiteUnits = () => {
    if (prefs.setUnitsFunc) {
      prefs.setUnitsFunc('site')
    }
    if (props.closer) {
      props.closer()
    }
  }

  return (
    <div className="context-menu-mask" style={{ left: props.posX }}>
      <div className="context-menu settings-menu">
        <ul className="mt-2">
          <li className="add-border-bottom">
            <label className="context-menu-check-area"></label>
            <span className="subtle">Settings</span>
          </li>
          <CheckMenuItem
            onClick={setOSThemeMode}
            label="Use Operating System Theme"
            isChecked={localStorage.getItem('prefs.colorTheme') === 'os'}
          />
          <CheckMenuItem
            onClick={setDarkMode}
            label="Use Dark Theme"
            isChecked={localStorage.getItem('prefs.colorTheme') === 'darkMode'}
          />
          <CheckMenuItem
            onClick={setLightMode}
            label="Use Light Theme"
            isChecked={localStorage.getItem('prefs.colorTheme') === 'lightMode'}
          />
          <CheckMenuItem
            onClick={setHighContrastMode}
            label="Use High-Contrast Theme"
            isChecked={localStorage.getItem('prefs.colorTheme') === 'highContrastMode'}
          />
          <hr />
          <CheckMenuItem
            onClick={setSiteUnits}
            label="Use Site's Preferred Units"
            isChecked={prefs.units === 'site'}
          />
          <CheckMenuItem
            onClick={setMetric}
            label="Use Metric Units"
            isChecked={prefs.units === 'metric'}
          />
          <CheckMenuItem
            onClick={setImperial}
            label="Use Imperial Units"
            isChecked={prefs.units === 'imperial'}
          />
        </ul>
      </div>
    </div>
  )
}

export default SettingsMenu
