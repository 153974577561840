import { useMemo } from "react"

import './CollapseMenuItem.css'

interface CollapseMenuItemProps {
    onClick: any
    isChecked: boolean
    checkType?: string
    label: string
    notSelectable?: boolean
    className?: string
}

export function CollapseMenuItem(props: CollapseMenuItemProps) {

  const { className, notSelectable } = props

  const classes = useMemo(() => {
    let base = 'collapse-menu-item'
    if (!notSelectable) {
      base += ' selectable'
    }
    if (className) {
      return `${base} ${className}`
    }
    return base
  }, [className, notSelectable])

  return (
    <li className={classes} onClick={props.onClick}>
      {props.label}
    </li>
  )
}