export interface UnitCoversion {
  sourceUnits: string
  sourceSystem: string
  destinationSystem: string
  destinationUnits: string
  converterFunc: any
  input: number,
  output: number
}

export interface UnitCoversionLookup {
  [key: string]: UnitCoversion
}

export const SystemImperial = 'imperial'
export const SystemMetric = 'metric'

// add new conversions here
//
export const unitConversions = [
  {
    sourceUnits: '°C',
    sourceSystem: SystemMetric,
    destinationUnits: '°F',
    destinationSystem: SystemImperial,
    converterFunc: cToF
  }
] as UnitCoversion[]
//
//

export const unitConversionsLookup = ucLookupFromList(unitConversions)

function converterKey(sourceUnits: string, destinationSystem: string): string {
  return `${sourceUnits}-${destinationSystem}`
}

function ucLookupFromList(input: UnitCoversion[]): UnitCoversionLookup {
  const out = {} as UnitCoversionLookup
  for (const cfg of input) {
    if (!cfg.sourceUnits || !cfg.destinationUnits || !cfg.converterFunc) {
      continue
    }
    out[converterKey(cfg.sourceUnits, cfg.destinationSystem)] = cfg
  }
  return out
}

// converterFuncs here

export function cToF(input: number): number {
  return Math.floor(((input * 9 / 5) + 32) * 10) / 10
}


// end converter funcs

// getConversion will take the input + the source units to determine if a conversion is
// necessary+available, and return a UnitConversion object
export function getConversion(input: number, sourceUnits: string, destinationSystem: string): UnitCoversion {
  const key = converterKey(sourceUnits, destinationSystem)
  if (key in unitConversionsLookup) {
    const c = { ...unitConversionsLookup[key] }
    c.input = input
    c.output = c.converterFunc(input)
    return c
  }
  return {
    sourceUnits: sourceUnits,
    destinationUnits: sourceUnits,
    input: input,
    output: input,
  } as UnitCoversion
}

// convert will take the input + the source units to determine if a conversion is
// necessary+available. if so, the converted value is returned, otherwise the original
export function convert(input: number, sourceUnits: string, destinationSystem: string): number {
  const key = converterKey(sourceUnits, destinationSystem)
  if (key in unitConversionsLookup) {
    return unitConversionsLookup[key].converterFunc(input)
  }
  return input
}

export function convertUnits(sourceUnits: string, destinationSystem: string): string {
  const key = converterKey(sourceUnits, destinationSystem)
  if (key in unitConversionsLookup) {
    return unitConversionsLookup[key].destinationUnits
  }
  return sourceUnits
}
