import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ReportDefinition, ReportRun } from "../../api/types";
import { headerColumn, row, rowCell } from "../controls/Tables/tables";
import { getReportRunsByDefId } from "../../api/endpoints";
import { ago } from "../../time/relativeTime";
import { runStatusLabel } from "./schedule";
import { PrefsContext } from "../../prefs/PrefsContext";

import BasicTable from "../controls/Tables/BasicTable";
import ButtonBasic from "../controls/Buttons/ButtonBasic";
import { ModalEditReportRun } from "./ModalEditReportRun";

import './ReportRunsTable.css'

interface ReportRunsTableProps {
  def: ReportDefinition
  runId?: string
}

const runStatusClass = (run: ReportRun): string => {
  if (run.status === 'success') {
    return 'report-run-success'
  }
  if (run.status === 'fail') {
    return 'report-run-failed'
  }
  return ''
}

export default function ReportRunsTable(props: ReportRunsTableProps) {

  const [isRunModalVisible, setIsRunModalVisible] = useState(false)
  const [run, setRun] = useState(undefined as ReportRun | undefined)
  const [runs, setRuns] = useState([] as ReportRun[])
  const { def, runId } = props
  const { isCustomerEditor } = useContext(PrefsContext)

  const nav = useNavigate()

  const refreshRuns = useCallback(() => {
    if (!def) {
      return
    }
    getReportRunsByDefId(def.customerID, def.id)
    .then((resp) => {
      setRuns(resp as ReportRun[])
    })
  }, [def])

  useEffect(() => {
    refreshRuns()
  }, [refreshRuns])

  useEffect(() => {
    if (!runId) {
      return
    }
    for (const r of runs) {
      if (r.id === runId) {
        setRun(r)
        setIsRunModalVisible(true)
      }
    }
  }, [runs, runId])

  useEffect(() => {
    if (isRunModalVisible || !runs || runs.length === 0) {
      return
    }
    let hasNew = false
    for (let i = 0; i < runs.length; i++) {
      if (runs[i] && (['new', 'running'].includes(runs[i].status))) {
        hasNew = true
        break
      }
    }
    if (!hasNew) {
      return
    }
    setTimeout(refreshRuns, 3000)
  }, [isRunModalVisible, refreshRuns, runs])

  const cols = [
    { text: 'Time' },
    { text: 'Status' },
    { text: '# Files' },
  ] as headerColumn[]

  const openRunEditor = useCallback((run?: ReportRun) => {
    setRun(run)
    setIsRunModalVisible(true)
  }, [setRun, setIsRunModalVisible])

  const sortedRuns = useMemo((): ReportRun[]  => {
    const out = [] as ReportRun[]
    if (!runs || runs.length === 0) {
      return out
    }
    for (let i = 0; i < runs.length; i++) {
      out.push(runs[i])
    }
    const compare = (a: ReportRun, b: ReportRun ): number => {
      if ((a.lastModified || 0) > (b.lastModified || 0)){
        return -1
      }
      if ((a.lastModified || 0) < (b.lastModified || 0)){
        return 1
      }
      return 0
    }
    out.sort(compare)
    if (out.length <= 10) {
      return out
    }
    return out.slice(0, 10)
  }, [runs])

  const rows = useMemo(() => {
    const out = [] as row[]
    if (sortedRuns.length === 0) {
      return out
    }
    for (var i = 0; i < sortedRuns.length; i++) {
      if (!sortedRuns[i]) {
        continue
      }
      const r = sortedRuns[i]
      out.push({
        cells: [
          {
            value: ago(sortedRuns[i].lastModified || 0),
            onClick: () => { openRunEditor(r) },
            className: 'ps-3'
          },
          {
            value: runStatusLabel(sortedRuns[i]),
            className: runStatusClass(sortedRuns[i])
          },
          {
            value: !sortedRuns[i].files ? 0 : sortedRuns[i].files.length 
          }
        ] as rowCell
      } as row)
    }
    return out
  }, [openRunEditor, sortedRuns])

  const closer = () => {
    nav(`/reports/${def.customerID}/${def.id}`, { replace: true })
    setIsRunModalVisible(false)
    refreshRuns()
  }

  return (
    <>
      <div className="basic-panel filled report-runs-table-container">
        <BasicTable
          className="report-runs-table"
          cols={cols}
          rows={rows}
          title="Recently Generated"
          noDataMessage="This report has not yet been generated."
        />
        {isCustomerEditor && <div className="report-runs-table-buttons">
          <ButtonBasic
            label="Generate This Report"
            onClick={() => {openRunEditor(undefined)}}
          />
        </div>}
      </div>
      {isRunModalVisible && <ModalEditReportRun def={props.def} initialRun={run} closer={closer} />}
    </>
  )
}