interface kvpSN {
    [key: string]: number
}

const relVals = {
    w: 86400 * 7,
    d: 86400,
    h: 3600,
    m: 60,
    s: 1
} as kvpSN


export function parseRelativeTime(input: string) : number[] {
    const out: number[] = []
    const times = input.split('-')
    if (!times[0] || times[0].length < 2 || !(times[0].slice(-1) in relVals)) {
        if (times.length === 1) {
            return out
        }
        out.push(-1)
    } else {
        const m = relVals[times[0].slice(-1)]
        let c = parseInt(times[0].slice(0, -1)) || 0
        let s = -1
        if (times[0].slice(0, 1) === "+") {
            s = 1
        }
        out.push( Math.floor(Date.now() / 1000)+(s*c*m))
    }
    if (!times[1] || times[1].length < 2 || !(times[1].slice(-1) in relVals)) {
        return out
    } else {
        const m = relVals[times[1].slice(-1)]
        let c = parseInt(times[1].slice(0, -1)) || 0
        let s = -1
        if (times[1].slice(0, 1) === "+") {
            s = 1
        }
        out.push( Math.floor(Date.now() / 1000)+(s*c*m))
    }
    return out
}

export const ago = (epoch: number): string => {
    if (!epoch) {
      return '--'
    }
    const now = Math.floor(Date.now() / 1000)
    const delta = now - epoch
    if (delta < 60) {
      return `${delta}s ago`
    }
    if (delta < 3600) {
      return `${Math.floor(delta / 60)}m ago`
    }
    if (delta < 86400) {
      return `${Math.floor(delta / 3600)}h ago`
    }
    if (delta < (86400 * 7)) {
      return `${Math.floor(delta / 86400)}d ago`
    }
    return `${Math.floor(delta / (86400 * 7))}w ago`
  }