export interface IChartOptions {
  [key: string]: any
}

export function BaseChartOptions(): IChartOptions {
  return {
    maintainAspectRatio: false,
    animation: {
      duration: 0
    },
    hover: {
      mode: 'nearest',
      intersect: false,
    },
    watermark: {
      image: '/agrology-ag.green.sm.png',
      x: 5,
      y: 4,
      width: 96,
      height: 20,
      alignX: 'left',
      alignY: 'bottom',
      alignToChartArea: true,
      position: 'front'
    },
    plugins: {
      legend: {
        labels: {
          color: '#888',
          pointStyle: 'rect',
          usePointStyle: true,
          padding: 20,
        },
        position: 'bottom',
        align: 'start',
        maxWidth: 200
      },
      title: {
        display: true,
        color: '#666e',
        text: '',
        font: {
          size: 14
        }
      }
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'hour'
        },
        grid: {
          tickLength: 10,
          z: -1,
          color: '#6663',
        },
        ticks: {
          maxTicksLimit: 11,
          maxRotation: 0,
          color: '#888',
          font: {
            size: 13,
          }
        }
      },
      y: {
        grid: {
          z: -1,
          color: '#6663',
        },
        ticks: {
          color: '#888',
          font: {
            size: 13
          },
          textStrokeWidth: 40,
          maxTicksLimit: 8
        },
        title: {
          display: false,
          text: ''
        }
      },
      y2: {
        position: 'right',
        grid: {
          z: -1,
          color: '#6663',
        },
        ticks: {
          color: '#888',
          font: {
            size: 13
          },
          textStrokeWidth: 40
        },
        title: {
          display: false,
          text: ''
        }
      }
    }
  } as IChartOptions
}
