import { useMemo } from "react"
import './Select.css'

export interface SelectItem {
  value: string
  label: string
}

interface SelectProps {
  id?: string
  label?: string
  children?: any
  onChange?: any
  defaultValue?: any
  disabled?: boolean
  className?: string
  labelClass?: string
  wrapperClass?: string
  items?: SelectItem[]
  noWrapper?: boolean
}

export default function Select(props: SelectProps) {

  const { className, labelClass, wrapperClass, disabled } = props

  const wrapperClasses = useMemo(() => {
    const base = 'select-wrapper'
    if (wrapperClass) {
      return `${base} ${wrapperClass}`
    }
    return base
  }, [wrapperClass])

  const classes = useMemo(() => {
    const base = 'input-control'
    if (className) {
      return `${base} ${className}`
    }
    return base
  }, [className])

  const labelClasses = useMemo(() => {
    let base = 'select-label'
    if (disabled) {
      base = `${base} subtle`
    }
    if (labelClass) {
      return `${base} ${labelClass}`
    }
    return base
  }, [labelClass, disabled])

  if (props.noWrapper) {
    return (
      <select
        className={classes}
        onChange={props.onChange} 
        defaultValue={props.defaultValue}
        disabled={props.disabled}
      >
        {(!props.items || props.items.length === 0) && props.children}
        {props.items && props.items.length > 0 &&
          props.items.map((item) => {
            return (
              <option key={item.value} value={item.value}>{item.label}</option>
            )
          })        
        }
      </select>
    )
  }

  return (
    <div className={wrapperClasses}>
      {props.label && <div className={labelClasses}>
      {props.label}:</div>}
      <select
        className={classes}
        onChange={props.onChange} 
        defaultValue={props.defaultValue}
        disabled={props.disabled}
      >
        {(!props.items || props.items.length === 0) && props.children}
        {props.items && props.items.length > 0 &&
          props.items.map((item) => {
            return (
              <option key={item.value} value={item.value}>{item.label}</option>
            )
          })        
        }
      </select>
    </div>
  )
}
