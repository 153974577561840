import { useMemo } from "react";

import { ReportDefinition } from "../../api/types";

import ReportDefinitionSettings from "./ReportDefinitionSettings";

import './ReportDefinitionDetail.css'
import ReportRunsTable from "./ReportRunsTable";

interface ReportDefinitionDetailProps {
  def: ReportDefinition
  runId?: string
  requestReloadFunc: any
}

export function ReportDefinitionDetail(props: ReportDefinitionDetailProps) {

  const classes = useMemo(() => {
    const baseClasses = `report-definition-detail`
    let fullClasses = baseClasses
    return fullClasses
  }, [])
  
  return (
    <>
    <div className={classes}>
      <div className="report-definition-detail-header">
        <h4>{props.def.description || 'Unnamed Report'}</h4>
      </div>
      <div className="report-definition-detail-body">
        <ReportDefinitionSettings def={props.def} requestReloadFunc={props.requestReloadFunc} />
        <ReportRunsTable def={props.def} runId={props.runId} />
      </div>
    </div>
    </>
  )
}