import { useState } from 'react'
import {
  faBuildingWheat,
  faChartArea,
  faMessage,
  faRectangleList,
  faTriangleExclamation,
  faScrewdriverWrench,
  faMapLocation,
  faUserShield
} from '@fortawesome/free-solid-svg-icons'

import  { MainNavLinkProps } from '../navigation/NavLink'
import NavMenu from '../navigation/NavMenu'
import ModalFeedbackForm from '../feedback/ModalFeedbackForm'

import './LeftNav.css'

export function LeftNav() {

  const [isFeedbackModalVisible, setIsFeedbackModalVisible] = useState(false)

  const links = [
    {label: 'Welcome', id: 'welcome', to: '/', cellClassName: 'rounded-top', icon: faBuildingWheat},
    {label: 'Charts', id: 'charts', to: '/charts', icon: faChartArea},
    {label: 'Reports', id: 'reports', to: '/reports', icon: faRectangleList, hideInProd: true},
    //
    {label: 'Alerting', id: 'alerting', to: '/alerting', icon: faTriangleExclamation, showInLocalDevOnly: true},
    {label: 'Maintenance', id: 'maintenance', to: '/maintenance', icon: faScrewdriverWrench, showInLocalDevOnly: true},
    {label: 'Sites', id: 'sites', to: '/sites', icon: faMapLocation, showInLocalDevOnly: true},
    {label: 'Manage Access', id: 'access', to: '/access', icon: faUserShield, showInLocalDevOnly: true},
    //
    {label: '', id: 'divider1', to: '', isDivider: true, hideInProd: true},
    //
    {label: 'Send Feedback', id: 'sendFeedback', onClick: () => {setIsFeedbackModalVisible(true)}, icon: faMessage}
  ] as MainNavLinkProps[]

  return (
    <>
      {isFeedbackModalVisible && 
        <ModalFeedbackForm closer={() => {setIsFeedbackModalVisible(false)}} />
      }
      <div className="controls-box leftnav">
        <NavMenu items={links}/>
      </div>
    </>
  )
}
