import { useEffect, useState } from "react"

import * as api from "../../api/endpoints"

import { IQueryConfig } from "./IQueryConfig"
import { Metric } from "../../api/types"

import SiteAndNodeSelector from "../fleet/SiteAndNodeSelector"
import SourceSelector from "./SourceSelector"
import { MetricSelector } from "./MetricSelector"
import YAxisSelector from "./YAxisSelector"
import ButtonBasic from "../controls/Buttons/ButtonBasic"
import ExpandingPanel from "../controls/Panels/ExpandingPanel"

import './QueryPane.css'
import LineStyleConfig from "./LineStyleConfig"
import { TimeBucketSelector } from "./TimeBucketSelector"

interface QueryPaneProps {
  queryConfig: IQueryConfig
  onQueryConfigChange: any
  queryIndex: number
  disableRemove?: boolean
  onRemove?: any
}

export default function QueryPane(props: QueryPaneProps) {


  const { queryConfig } = props
  const { source } = queryConfig

  if (!queryConfig.axis) {
    queryConfig.axis = 'y'
  }
  if (!queryConfig.style) {
    queryConfig.style = 'line'
  }

  const [metrics, setMetrics] = useState([] as Metric[])

  // this updates the metric selector whenever the metrics source changes
  useEffect(() => {
    let f = undefined as any
    switch (source) {
      case "gt-historical":
        f = api.getGroundTruthMetrics
        break
      case "weather-historical":
        f = api.getHistoricalWeatherMetrics
        break
      case "synthetic":
        f = api.getSyntheticNanoclimateMetrics
        break
      case "weather-forecast":
        f = api.getPredictionWeatherMetrics
        break
      case "nanoclimate-forecast":
        f = api.getPredictionNanoclimateMetrics
        break
    }
    if (f) {
      f()
      .then((resp: any) => {
        setMetrics(resp)
      })
      .catch((e: any) => {
        console.log('ERROR getting metrics', source, e)
      })
    }

  }, [source])

  // queryConfig Updates:
  const siteChanged = (e: any) => {
    if (!e || !e.target || !('value' in e.target)) {
      return
    }
    const out = { ...queryConfig } as IQueryConfig
    out.site = e.target.value
    out.node = ''
    props.onQueryConfigChange(out, props.queryIndex)
  }

  const nodeChanged = (e: any) => {
    if (!e || !e.target || !('value' in e.target)) {
      return
    }
    const out = { ...queryConfig } as IQueryConfig
    out.node = e.target.value
    props.onQueryConfigChange(out, props.queryIndex)
  }

  const sourceChanged = (e: any) => {
    if (!e || !e.target || !('value' in e.target)) {
      return
    }
    const out = { ...queryConfig } as IQueryConfig
    out.source = e.target.value
    props.onQueryConfigChange(out, props.queryIndex)
  }

  const metricChanged = (e: any) => {
    let val = e
    if (e.target && 'value' in e.target) {
      val = e.target.value
    }
    const out = { ...queryConfig } as IQueryConfig
    out.metric = val
    props.onQueryConfigChange(out, props.queryIndex)
  }

  const axisChanged = (e: any) => {
    if (!e || !e.target || !('value' in e.target)) {
      return
    }
    const out = { ...queryConfig } as IQueryConfig
    out.axis = e.target.value
    props.onQueryConfigChange(out, props.queryIndex)
  }

  const lineStyleChanged = (e: any) => {
    if (!e || !e.target || !('value' in e.target)) {
      return
    }
    const out = { ...queryConfig } as IQueryConfig
    out.style = e.target.value
    props.onQueryConfigChange(out, props.queryIndex)
  }

  const timeBucketChanged = (useBucketing: boolean, size: number, operation: string) => {
    const out = { ...queryConfig } as IQueryConfig
    out.useBuckets = useBucketing || false
    out.bucketSize = size
    out.bucketOp = operation
    props.onQueryConfigChange(out, props.queryIndex)
  }

  return (
    <ExpandingPanel
      title={`Query ${props.queryIndex + 1}`}
      openClassName="query-pane-open"
      defaultIsOpen={true}
    >
      <SiteAndNodeSelector
        wrapperClass="query-pane-sitenode-selector"
        labelClass="query-pane-row-label"
        className="query-pane-select"
        siteId={queryConfig.site}
        nodeId={queryConfig.node}
        onSiteChange={siteChanged}
        onNodeChange={nodeChanged}
      />
      <SourceSelector
        onChange={sourceChanged}
        source={queryConfig.source}
        key={queryConfig.source}
        labelClass="query-pane-row-label"
        className="query-pane-select"
      />
      <MetricSelector
        onChange={metricChanged}
        defaultValue={queryConfig.metric}
        labelClass="query-pane-row-label"
        key={'metrics-list-' + queryConfig.metric + '-' + queryConfig.source + '-' + metrics.length}
        source={queryConfig.source}
        metrics={metrics}
      />
      <YAxisSelector
        onChange={axisChanged}
        labelClass="query-pane-row-label"
        queryIndex={props.queryIndex}
        defaultValue={queryConfig.axis}
        key={queryConfig.site + '-' + queryConfig.axis}
      />
      <LineStyleConfig
        onChange={lineStyleChanged}
        labelClass="query-pane-row-label"
        queryIndex={props.queryIndex}
        defaultValue={queryConfig.style}
        key={queryConfig.site + '-' + queryConfig.style}
      />
      <TimeBucketSelector
        onChange={timeBucketChanged}
        defaultBucketingOn={queryConfig.useBuckets || false}
        defaultBucketSize={queryConfig.bucketSize}
        defaultOperation={queryConfig.bucketOp}
        key={queryConfig.id + '-' + queryConfig.useBuckets + '.' + queryConfig.bucketOp + '.' + queryConfig.bucketSize}
      />
      {!props.disableRemove &&
        <>
          <hr />
          <div className="centered mt-2 mb-1">
            <ButtonBasic
              className="button-red"
              size="sm"
              outline={true}
              onClick={() => { if (props.onRemove) { props.onRemove(props.queryIndex)} }}
              label={'Remove Query ' + (props.queryIndex + 1)}
            />
          </div>
        </>
      }
    </ExpandingPanel>
  )
}
