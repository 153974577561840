import { useLocation } from 'react-router'
import { useAuth } from "../../auth/provider"
import LoginModal from './LoginModal'

export function Login() {

  const ad = useAuth()
  const location = useLocation()

  if (ad.isInitializing) {
    return <></>
  }

  // IF - user is signed in:
  if (ad.isAuthenticated) {
    // this extracts the post-login a redirect path for the user from the url
    let from = location.state?.from?.pathname || '/'
    if (from === '/login') {
      from = '/'
    }
    window.location.pathname = from
    return (<></>)
  }
  //
  //
  // ELSE - user is not signed in:
  return (
    <LoginModal />
  )
}