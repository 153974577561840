import { useCallback, useContext, useMemo, useState } from "react";

import { faCheck, faEnvelope, faMessage } from "@fortawesome/free-solid-svg-icons"

import { PrefsContext } from "../../prefs/PrefsContext";

import * as types from '../../api/types'
import { GetLocalizedTextItem } from "../../localization/localization";
import { postFeedback } from "../../api/endpoints";
import { VERSION } from "../../app/app";

import ModalBasic, { ButtonConfig } from "../modals/ModalBasic"
import Select, { SelectItem } from "../controls/Selects/Select";
import TextArea from "../controls/TextArea/TextArea";
import ToggleSwitch from "../controls/CheckBox/ToggleSwitch";
import Icon from "../controls/Icon/Icon";

import './ModalFeedbackForm.css'

interface ModalFeedbackFormProps {
  closer?: any
}

export default function ModalFeedbackForm(props: ModalFeedbackFormProps) {
  const { language, customerAccess } = useContext(PrefsContext)
  const [wasSent, setWasSent] = useState(false)
  const [subj, setSubj] = useState(GetLocalizedTextItem(language, 'feedbackReasonBugReport'))
  const [remarks, setRemarks] = useState('')
  const [shouldFollowUp, setShouldFollowUp] = useState(true)
  const [didError, setDidError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const FeedbackSubjectOptions = [
  {
    value: GetLocalizedTextItem(language, 'feedbackReasonBugReport'),
    label: GetLocalizedTextItem(language, 'feedbackReasonBugReport'),
  },
  {
    value: GetLocalizedTextItem(language, 'feedbackReasonFeatureRequest'),
    label: GetLocalizedTextItem(language, 'feedbackReasonFeatureRequest'),
  },
  {
    value: GetLocalizedTextItem(language, 'feedbackReasonServiceIssue'),
    label: GetLocalizedTextItem(language, 'feedbackReasonServiceIssue'),
  },
  {
    value: GetLocalizedTextItem(language, 'feedbackReasonHardwareIssue'),
    label: GetLocalizedTextItem(language, 'feedbackReasonHardwareIssue'),
  },
  {
    value: GetLocalizedTextItem(language, 'feedbackReasonKudos'),
    label: GetLocalizedTextItem(language, 'feedbackReasonKudos'),
  },
  {
    value: GetLocalizedTextItem(language, 'feedbackReasonSomethingElse'),
    label: GetLocalizedTextItem(language, 'feedbackReasonSomethingElse'),
  },
  ] as SelectItem[]

  const onSubmit = useCallback(() => {
    setIsLoading(true)
    const s = {
      client: process.env.REACT_APP_appName,
      clientVersion: 'v' + VERSION,
      customer: customerAccess?.customer + " / " + customerAccess?.displayName,
      feedbackType: subj,
      remarks: remarks,
      wantsFollowUp: shouldFollowUp,
      appLocation: window.location.href
    } as types.FeedbackSubmission
    postFeedback(s)
    .then(() => {
      setDidError(false)
    })
    .catch((e) => {
      console.log('Error posting feedback!!', e)
      setDidError(true)
    })
    .finally(() => {
      setWasSent(true)
      setIsLoading(false)
    })
  }, [subj, remarks, shouldFollowUp, customerAccess])

  const buttonConfigs = useMemo(() => {
    if (wasSent) {
      return [] as ButtonConfig[]
    }
    return [{
      title: GetLocalizedTextItem(language, 'feedbackFormSend'),
      className: "me-3",
      onClick: onSubmit,
      disabled: isLoading || didError || !remarks || remarks === ''
    }] as ButtonConfig[]
  }, [wasSent, language, remarks, isLoading, didError, onSubmit])

  const closeLabel = useMemo(() => {
    if (wasSent) {
      return GetLocalizedTextItem(language, 'close', 'upperAllFirst')
    }
    return GetLocalizedTextItem(language, 'cancel', 'upperAllFirst')
  }, [wasSent, language])

  const subjChanged = (e: any) => {
    if (e && e.target && e.target.value) {
      setSubj(e.target.value)
    }
  }

  const remarksChanged = (e: any) => {
    if (e && e.target && e.target.value) {
      setRemarks(e.target.value)
    } else {
      setRemarks('')
    }
  }

  const followUpChanged = (e: any) => {
    setShouldFollowUp(e && e.target && e.target.checked)
  }

  return (
    <ModalBasic
      headerIcon={faMessage}
      headerText={
        GetLocalizedTextItem(language, 'feedbackFormSend') + ' ' +
        GetLocalizedTextItem(language, 'to') + ' ' +
        process.env.REACT_APP_appShortName}
      sz="sm"
      closer={props.closer}
      closeLabel={closeLabel}
      buttonConfigs={buttonConfigs}
    >
      <div className="feedback-form-body">
      {didError && 
        <div className="feedback-form-confirmation font-increase-1">
          {GetLocalizedTextItem(language, 'feedbackFormSendError')}
        </div>
      }
      {!didError && wasSent && 
        <div className="feedback-form-confirmation">
          <Icon FAIcon={faCheck} color="green" sz="large" />
          {GetLocalizedTextItem(language, 'feedbackFormConfirmationP1')}
          {shouldFollowUp && <>
            <br /><br />
          {GetLocalizedTextItem(language, 'feedbackFormConfirmationP2')}
          <Icon FAIcon={faEnvelope} sz="large" />
          </>
          }
        </div>
      }
      {!didError && !wasSent && <>
        <div className="feedback-form-intro">
          { GetLocalizedTextItem(language, 'feedbackFormIntroP1')}
        </div>
        <div className="feedback-form-intro">
          { GetLocalizedTextItem(language, 'feedbackFormIntroP2')}
        </div>
        <hr />
        <div className="feedback-form-input-list">
          <div className="feedback-form-input-header">
            { GetLocalizedTextItem(language, 'feedbackFormSubjectHeader')}
          </div>
          <Select
            className="feedback-form-input-control"
            items={FeedbackSubjectOptions}
            onChange={subjChanged}
          />

          <div className="feedback-form-input-header">
            { GetLocalizedTextItem(language, 'feedbackFormRemarksHeader')}
          </div>
          <TextArea
            className="feedback-form-input-control feedback-form-remarks-input"
            footnote={GetLocalizedTextItem(language, 'feedbackFormNoSensitiveInfo')}
            onChange={remarksChanged}
          />
          <div className="feedback-form-input-header">
            { GetLocalizedTextItem(language, 'feedbackFormFollowupHeader')}
            <ToggleSwitch className="ms-3" onClick={followUpChanged} defaultChecked={true} />
          </div>
        </div>
      </>}
      </div>
    </ModalBasic>
  )
}
