export interface ReportEntry {
  id: string
  title: string
}

export const GrowersReportTemplateID = '5c9da819-327b-486a-b070-ef6ad9ed23c0'

export const supportedReports = [
  {
    id: GrowersReportTemplateID,
    title: "Grower's Report"
  } as ReportEntry
] as ReportEntry[] 

export const supportedReportsLookup = {} as {[key: string]: ReportEntry} 

for (const entry of supportedReports) {
  supportedReportsLookup[entry.id] = entry
}

export const unknownReport = {
  id: '00000000-0000-0000-0000-000000000000',
  title: 'Unknown Report Type'
} as ReportEntry
