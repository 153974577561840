import { useContext, useMemo } from "react"
import { Link } from "react-router-dom";

import { PrefsContext } from "../../../prefs/PrefsContext"
import { GetLocalizedTextItem } from "../../../localization/localization"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons"

import './ListBoxNav.css'


export interface ListBoxNavElement {
  id: string
  label: string
  content: any
}

interface ListBoxNavProps {
  title?: string
  elements: ListBoxNavElement[]
  selectedElement?: string
  className?: string
  noElementsContent?: any
  labelWhenElementOpen?: string
  openLabelHref?: string
}

export default function ListBoxNav(props: ListBoxNavProps) {

  const { language, customer } = useContext(PrefsContext)

  const { className, elements, noElementsContent, selectedElement } = props

  const classes = useMemo(() => {
    const base = 'listbox-nav'
    if (className) {
      return `${base} ${className}`
    }
    return base
  }, [className])

  const noElementsContentComputed = useMemo(() => {
    if (noElementsContent) {
      return noElementsContent
    }
    return GetLocalizedTextItem(language, 'noItems', 'upperAllFirst')
  }, [noElementsContent, language])

  const selectedIndex = useMemo((): number => {
    if (!selectedElement || !elements || elements.length === 0) {
      return -1
    }
    for (let i=0; i<elements.length; i++) {
      if (elements[i].id === selectedElement) {
        return i
      }
    }
    return -1
  }, [elements, selectedElement])

  if (!elements || elements.length === 0) {
    return (
      <div className={classes}>
        <div className="listbox-nav-list-empty">
          {noElementsContentComputed}
        </div>
      </div>
    )
  }

  if (selectedIndex >= 0) {
    return (
      <div className={classes}>
        <div className="listbox-nav-list-element-row">
          {props.openLabelHref && <Link to={props.openLabelHref} className="listbox-nav-list-back circle-icon"><FontAwesomeIcon icon={faChevronLeft} /></Link>}
          {!props.openLabelHref && <label className="listbox-nav-list-element-label">{props.labelWhenElementOpen || elements[selectedIndex].label}</label>}
          {props.openLabelHref &&  <Link to={props.openLabelHref} className="listbox-nav-list-element-label">{props.labelWhenElementOpen || elements[selectedIndex].label}</Link>}
        </div>
        <div className="listbox-nav-list-elememt-content">
          {elements[selectedIndex].content}
        </div>
      </div>
    )
  }

  return (
    <div>
      {props.title && <div className="listbox-nav-list-title">{props.title}</div>}
      <div className={classes}>
        <ul>
        {elements.map((elem, i) => {
          return (
            <li
              key={i}
              className="listbox-nav-list-element-row listbox-nav-list-element-inactive"
            >
              <Link to={customer + '/' + elem.id} className="listbox-nav-list-link">
                <div className="listbox-nav-list-link-content">
                  <div className="listbox-nav-list-element-row-label ms-2 wide">{elem.label}</div>
                  <div className="listbox-nav-list-element-row-select circle-icon me-2"><FontAwesomeIcon icon={faChevronRight}/></div>
                </div>
              </Link>
            </li>
          )
        })}
        </ul>
      </div>
    </div>
  )
}
