import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear } from '@fortawesome/free-solid-svg-icons'

interface RightHeaderProps {
    isMenuVisible: boolean
    toggleMenu: any
}

export function RightHeader(props: RightHeaderProps) {

    const iconClassName = (isMenuVisible: boolean) => {
        if (isMenuVisible) {
            return "navbar-icon navbar-icon-active"
        }
        return "navbar-icon"
    }

    return (
        <div className="nav-right-area justify-content-end align-items-center">
            {/* <FontAwesomeIcon icon={faSearch} className="navbar-icon me-3" /> */}
            <FontAwesomeIcon id="settingsIcon" icon={faGear} className={iconClassName(props.isMenuVisible)} onClick={props.toggleMenu} />
        </div>
    )
}
