import { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { ReportDefinition } from '../../api/types'
import { GrowersReportTemplateID, supportedReports } from './supportedReports'
import { reportCadences, reportRanges } from './selectItems'
import { dayOfMonthOffsets, dayOfWeekOffsets, hourOffsets, minuteOffsets } from '../../time/offests'
import { GetLocalizedTextItem } from '../../localization/localization'
import { createReportDefinition, deleteReportDefinition, updateReportDefinition } from '../../api/endpoints'
import { PrefsContext } from '../../prefs/PrefsContext'
import { FlashMessageContext } from '../notifications/FlashMessage/FlashMessageContext'

import Select, { SelectItem } from '../controls/Selects/Select'
import ModalBasic, { ButtonConfig, ModalColumnDivider } from '../modals/ModalBasic'
import CheckBox from '../controls/CheckBox/CheckBox'
import TextArea from '../controls/TextArea/TextArea'
import TextInput from '../controls/TextInput/TextInput'
import SiteAndNodeSelector from '../fleet/SiteAndNodeSelector'
import GrowerReportSettingsPanel from './GrowerReportSettingsPanel'

import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'

import './ModalEditReportDefinition.css'

const getDayFromDef = (def: ReportDefinition): number => {
  if (def && def.offsetSecs) {
    const out = Math.floor(def.offsetSecs / 86400)
    if (out < 1) {
      return 1
    }
    return out
  }
  return 1
}

const getHourFromDef = (def: ReportDefinition): number => {
  if (def && def.offsetSecs) {
    return Math.floor((def.offsetSecs % 86400) / 3600)
  }
  return 0
}

const getMinFromDef = (def: ReportDefinition): number => {
  if (def && def.offsetSecs) {
    return Math.floor(((def.offsetSecs % 86400) % 3600) / 60)
  }
  return 0
}

interface ModalEditReportDefinitionProps {
  def: ReportDefinition
  closer?: any
  setSubmittableDef?: any
  requestReloadFunc: any
}

function ModalEditReportDefinitionBody(props: ModalEditReportDefinitionProps) {
  const { def, setSubmittableDef } = props
  const [localDef, setLocalDef] = useState({ ...def } as ReportDefinition)
  const [localDayOfWeek, setLocalDayOfWeek] = useState(getDayFromDef(localDef))
  const [localDayOfMonth, setLocalDayOfMonth] = useState(getDayFromDef(localDef))
  const [localHour, setLocalHour] = useState(getHourFromDef(localDef))
  const [localMinute, setLocalMinute] = useState(getMinFromDef(localDef))
  const [localSite, setLocalSite] = useState(localDef.scope === 'site' ? localDef.targetID : '')

  const prefs = useContext(PrefsContext)

  const localOffset = useMemo((): number => {
    if (localDef.entryType === 'monthly') {
      return (localDayOfMonth * 86400) + (localHour * 3600) + (localMinute * 60)
    }
    if (localDef.entryType === 'weekly') {
      return (localDayOfWeek * 86400) + (localHour * 3600) + (localMinute * 60)
    }
    if (localDef.entryType === 'daily') {
      return (localHour * 3600) + (localMinute * 60)
    }
    return 0
  }, [localDef, localDayOfWeek, localDayOfMonth, localHour, localMinute])

  const reportTypeItems = useMemo((): SelectItem[] | undefined => {
    const out = [] as SelectItem[]
    for (const item of supportedReports) {
      out.push({label: item.title, value: item.id} as SelectItem)
    }
    if (out.length === 0) {
      return undefined
    }
    return out
  }, [])

  const showDaysOfWeek = useMemo((): boolean => {
    return localDef.entryType === 'weekly'
  }, [localDef])

  const showDaysOfMonth = useMemo((): boolean => {
    return localDef.entryType === 'monthly'
  }, [localDef])

  const showHours = useMemo((): boolean => {
    return ['weekly', 'daily', 'monthly'].includes(localDef.entryType)
  }, [localDef])

  const showMinutes = useMemo((): boolean => {
    return ['weekly', 'daily', 'monthly'].includes(localDef.entryType)
  }, [localDef])

  const hoursList = useMemo((): SelectItem[] => {
    const out = [] as SelectItem[]
    for (const hour of hourOffsets) {
      out.push({value: hour.hour.toString(), label: hour.formatted})
    }
    return out
  }, [])

  const minutesList = useMemo((): SelectItem[] => {
    const out = [] as SelectItem[]
    for (const min of minuteOffsets) {
      out.push({value: min.minute.toString(), label: min.formatted.replaceAll(':', '')})
    }
    return out
  }, [])

  const daysOfMonthList = useMemo((): SelectItem[] => {
    const out = [] as SelectItem[]
    for (const day of dayOfMonthOffsets) {
      out.push({value: day.input.toString(), label: day.formatted})
    }
    return out
  }, [])

  const daysOfWeekList = useMemo((): SelectItem[] => {
    const out = [] as SelectItem[]
    for (const day of dayOfWeekOffsets) {
      out.push({value: day.input.toString(), label: day.formatted})
    }
    return out
  }, [])

  const defSite = useMemo((): string => {
    if (!localDef || !localDef.scope || !localDef.targetID) {
      return ''
    }
    if (localDef.scope === 'customer') {
      return 'all'
    }
    if (localDef.scope === 'site') {
      return localDef.targetID
    }
    if (localDef.scope === 'node') {
      const parts = localDef.targetID.split('.')
      if (parts.length === 2) {
        return parts[0]
      }
    }
    return ''
  }, [localDef])

  const defNode = useMemo((): string => {
    if (!localDef || !localDef.scope || !localDef.targetID) {
      return ''
    }
    if (localDef.scope === 'node') {
      const parts = localDef.targetID.split('.')
      if (parts.length === 2) {
        return parts[1]
      }
    }
    return ''
  }, [localDef])

  const cadenceChanged = (e: any) => {
    if (e && e.target && 'value' in e.target) {
      const d = {...localDef} as ReportDefinition
      d.entryType = e.target.value
      setLocalDef(d)
    }
  }

  const hourChanged = (e: any) => {
    if (e && e.target && 'value' in e.target) {
      setLocalHour((e.target.value) * 1)
    }
  }

  const minChanged = (e: any) => {
    if (e && e.target && 'value' in e.target) {
      setLocalMinute((e.target.value) * 1)
    }
  }

  const dowChanged = (e: any) => {
    if (e && e.target && 'value' in e.target) {
      setLocalDayOfWeek((e.target.value) * 1)
    }
  }

  const domChanged = (e: any) => {
    if (e && e.target && 'value' in e.target) {
      setLocalDayOfMonth((e.target.value) * 1)
    }
  }

  const toChanged = (e: any) => {
    if (e && e.target && 'value' in e.target) {
      const d = {...localDef}
      if (!e.target.value) {
        delete d.recipients
      } else {
        d.recipients = (e.target.value).split(',')
      }
      setLocalDef(d)
    }
  }

  const ccChanged = (e: any) => {
    if (e && e.target && 'value' in e.target) {
      const d = {...localDef}
      if (!e.target.value) {
        delete d.cc
      } else {
        d.cc = (e.target.value).split(',')
      }
      setLocalDef(d)
    }
  }

  const bccChanged = (e: any) => {
    if (e && e.target && 'value' in e.target) {
      const d = {...localDef}
      if (!e.target.value) {
        delete d.bcc
      } else {
        d.bcc = (e.target.value).split(',')
      }
      setLocalDef(d)
    }
  }

  const descriptionChanged = (e: any) => {
    if (e && e.target && 'value' in e.target) {
      const d = {...localDef, description: e.target.value}
      setLocalDef(d)
    }
  }

  const timeRangeChanged = (e: any) => {
    if (e && e.target && 'value' in e.target) {
      const d = {...localDef, timeRange: e.target.value}
      setLocalDef(d)
    }
  }

  const aggregateChanged = (e: any) => {
    if (e && e.target && 'value' in e.target) {
      const d = {...localDef, aggregate: e.target.value}
      setLocalDef(d)
    }
  }

  const aggregateFilesChanged = (e: any) => {
    if (e && e.target && 'value' in e.target) {
      const d = {...localDef, aggregateFiles: e.target.value}
      setLocalDef(d)
    }
  }

  const metadataChanged = (md: { [key: string]: string }) => {
    const d = {...localDef, metadata: md}
    setLocalDef(d)
  }

  const emailEnabledChanged = (e: any) => {
    if (e && e.target && 'checked' in e.target) {
      const d = {...localDef, emailEnabled: e.target.checked ? 1 : 0 }
      setLocalDef(d)
    }
  }

  const scopeTargetChanged = (e: any) => {
    if (e && e.target && 'value' in e.target) {
      const v = e.target.value
      const d = {...localDef}
      if (v === 'all') {
        d.scope = 'customer'
        d.targetID = ''
        setLocalSite('')
      } else if (v) {
        d.scope = 'site'
        d.targetID = v
        setLocalSite(v)
      } else {
        return
      }
      setLocalDef(d)
    }
  }

  const scopeTargetNodeChanged = (e: any) => {
    if (e && e.target && 'value' in e.target) {
      const v = e.target.value
      const d = {...localDef}
      if (!v) {
        if (!localSite) {
          d.scope = 'customer'
        } else {
          d.scope = 'site'
          d.targetID = localSite
        }
      } else {
        d.scope = 'node'
        d.targetID = `${localSite}.${v}`
      }
      setLocalDef(d)
    }
  }

  const submittableDef = useMemo((): ReportDefinition => {
    
    // filters any unwanted/readonly properties to prevent API rejection errors
    const out = {
      id: localDef.id,
      aggregate: localDef.aggregate,
      customerID: localDef.customerID,
      description: localDef.description,
      emailEnabled: localDef.emailEnabled,
      entryType: localDef.entryType,
      metadata: localDef.metadata,
      offsetSecs: localOffset,
      recipients: localDef.recipients,
      cc: localDef.cc,
      bcc: localDef.bcc,
      scope: localDef.scope,
      targetID: localDef.targetID,
      templateID: localDef.templateID,
      timeRange: localDef.timeRange,
    } as ReportDefinition
    return out
  }, [localDef, localOffset])

  useEffect(() => {
    if (submittableDef) {
      setSubmittableDef(submittableDef)
    }
  }, [submittableDef, setSubmittableDef])

  if (!props.def) {
    return <></>
  }

  return (
    <div className="modal-edit-report-def-body">
      <div className="modal-edit-report-def-body-col">
        <div className="modal-edit-report-def-section-header">
          {GetLocalizedTextItem(prefs.language, 'generalSettings', 'upperAllFirst')}
        </div>
        <TextInput
          label={GetLocalizedTextItem(prefs.language, 'description', 'upperAllFirst')}
          labelClass="width-85"
          className="wide"
          wrapperClass="mb-2"
          defaultValue={def.description}
          onChange={descriptionChanged}
          required={true}
        />

        <Select
          label={GetLocalizedTextItem(prefs.language, 'reportType', 'upperAllFirst')}
          labelClass="width-85"
          className="wide"
          wrapperClass="mb-2"
          items={reportTypeItems}
          defaultValue={def.templateID}
        />

        <SiteAndNodeSelector
          labelClass="width-85"
          hideSelectASite={true}
          showAllSitesOption={true}
          siteId={defSite}
          nodeId={defNode}
          onSiteChange={scopeTargetChanged}
          onNodeChange={scopeTargetNodeChanged}
        />

        <Select
          label={GetLocalizedTextItem(prefs.language, 'timePeriod', 'upperAllFirst')}
          labelClass="width-85"
          className="wide"
          wrapperClass="mb-2"
          items={reportRanges}
          defaultValue={def.timeRange}
          onChange={timeRangeChanged}
        />

        {def && def.templateID && def.templateID === GrowersReportTemplateID &&
          <GrowerReportSettingsPanel
            def={def}
            aggregateChanged={aggregateChanged}
            aggregateFilesChanged={aggregateFilesChanged}
            metadataChanged={metadataChanged}
          />
        }
      </div>

      <ModalColumnDivider />

      <div className="modal-edit-report-def-body-col">

      <div className="modal-edit-report-def-section-header">
          {GetLocalizedTextItem(prefs.language, 'scheduleSettings', 'upperAllFirst')}
        </div>

        <Select
          label={GetLocalizedTextItem(prefs.language, 'cadence', 'upperAllFirst')}
          labelClass="width-85"
          className="wide"
          wrapperClass="mb-2"
          items={reportCadences}
          defaultValue={def.entryType}
          onChange={cadenceChanged}
        />

        {showDaysOfMonth && <Select
          label={GetLocalizedTextItem(prefs.language, 'day', 'upperAllFirst')}
          labelClass="width-85"
          className="wide"
          wrapperClass="mb-2"
          items={daysOfMonthList}
          defaultValue={localDayOfMonth}
          onChange={domChanged}
        />}

        {showDaysOfWeek && <Select
          label={GetLocalizedTextItem(prefs.language, 'day', 'upperAllFirst')}
          labelClass="width-85"
          className="wide"
          wrapperClass="mb-2"
          items={daysOfWeekList}
          defaultValue={localDayOfWeek}
          onChange={dowChanged}
        />}

        <div className="d-flex wide">
          {showHours && <Select
            label={GetLocalizedTextItem(prefs.language, 'hour', 'upperAllFirst')}
            labelClass="width-85"
            className="wide"
            wrapperClass="mb-2 wide"
            items={hoursList}
            defaultValue={localHour}
            onChange={hourChanged}
          />}
          {showMinutes && <Select
            label={GetLocalizedTextItem(prefs.language, 'minute', 'upperAllFirst')}
            className="width-50"
            wrapperClass="mb-2 ms-3"
            items={minutesList}
            defaultValue={localMinute}
            onChange={minChanged}
          />}
        </div>

        <div className="modal-edit-report-def-section-header">
          {GetLocalizedTextItem(prefs.language, 'emailSettings', 'upperAllFirst')}
        </div>

        <CheckBox
          label={GetLocalizedTextItem(prefs.language, 'sendReportViaEmail', 'upperAllFirst')}
          wrapperClass="width-fc mb-3"
          labelClass="ps-2"
          defaultChecked={def.emailEnabled === 1}
          onClick={emailEnabledChanged}
        />
        
        <TextArea
          label={GetLocalizedTextItem(prefs.language, 'to', 'upperAllFirst')}
          labelClass="width-35"
          className="recipients-input"
          wrapperClass="mb-2"
          defaultValue={def.recipients && def.recipients.length > 0 ? def.recipients.join(',') : ''}
          onChange={toChanged}
          disabled={!localDef.emailEnabled}
        />

        <TextArea
          label={GetLocalizedTextItem(prefs.language, 'cc', 'upper')}
          labelClass="width-35"
          className="recipients-input"
          wrapperClass="mb-2"
          defaultValue={def.cc && def.cc.length > 0 ? def.cc.join(',') : ''}
          onChange={ccChanged}
          disabled={!localDef.emailEnabled}
        />

        <TextArea
          label={GetLocalizedTextItem(prefs.language, 'bcc', 'upper')}
          labelClass="width-35"
          className="recipients-input"
          defaultValue={def.bcc && def.bcc.length > 0 ? def.bcc.join(',') : ''}
          onChange={bccChanged}
          disabled={!localDef.emailEnabled}
        />

        <div className="mt-2 ms-4 subtle font-reduce-2 nowrap">
          {GetLocalizedTextItem(prefs.language, 'separateAddressesWithComma', 'upperFirst')}
        </div>

      </div> 
    </div>
  )
}

export function ModalEditReportDefinition(props: ModalEditReportDefinitionProps) {

  const [submittableDef, setSubmittableDef] = useState({} as ReportDefinition)
  const [closeRequested, setCloseRequested] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { language, customer } = useContext(PrefsContext)
  const { setFlashMessage } = useContext(FlashMessageContext)

  const { requestReloadFunc } = props

  useEffect(() => {
  }, [submittableDef])

  const saveChanges = useCallback(() => {

    const reportDefID = submittableDef.id || ''
    const def = {...submittableDef} as any

    delete def.customerID 
    delete def.id 
    if (!def.recipients) {
      def.recipients = [] as string[]
    }
    if (!def.cc) {
      def.cc = [] as string[]
    }
    if (!def.bcc) {
      def.bcc = [] as string[]
    }
    if ((!('emailEnabled' in def)) || def.emailEnabled === undefined) {
      def.emailEnabled = 0
    }
    if ((!('scope' in def)) || def.scope === undefined) {
      def.scope = 'customer'
    }
    if ((!('targetID' in def)) || def.targetID === undefined) {
      def.targetID = ''
    }

    if (reportDefID) {
      // update existing report
      setIsLoading(true)
      updateReportDefinition(customer, reportDefID, def)
        .then(() => {
          setFlashMessage('Report Settings Updated.', 'ok', 3000, faCheck)
          setCloseRequested(true)
          if (requestReloadFunc) {
            requestReloadFunc()
          }
        })
        .catch((e) => {
          setFlashMessage('Could not update Report Settings. ' + e, 'error', 0, faTimes)
        })
        .finally(() => {
          setIsLoading(false)
        })
    } else {
      // create new report
      setIsLoading(true)
      createReportDefinition(customer, def)
        .then(() => {
          setFlashMessage('Report created.', 'ok', 3000, faCheck)
          setCloseRequested(true)
          if (requestReloadFunc) {
            requestReloadFunc()
          }
        })
        .catch((e) => {
          setFlashMessage('Could not create Report. ' + e, 'error', 0, faTimes)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [setFlashMessage, submittableDef, setIsLoading, setCloseRequested, requestReloadFunc, customer])

  const deleteReport = useCallback(() => {
    if (!submittableDef.id) {
      return
    }
    setIsLoading(true)
    deleteReportDefinition(customer, submittableDef.id)
    .then(() => {
      setFlashMessage('Report deleted.', 'ok', 3000, faCheck)
      setCloseRequested(true)
      if (requestReloadFunc) {
        requestReloadFunc()
      }
    })
    .catch((e) => {
      setFlashMessage('Could not delete Report. ' + e, 'error', 0, faTimes)
    })
    .finally(() => {
      setIsLoading(false)
    })
  }, [submittableDef, customer, requestReloadFunc, setFlashMessage, setCloseRequested, setIsLoading])

  const buttonConfigs = useMemo(() => {

    // edit
    if (submittableDef.id) {
      return [{
        title: GetLocalizedTextItem(language, 'saveChanges', 'upperAllFirst'),
        onClick: saveChanges,
        className: "me-2",
        disabled: isLoading || !submittableDef.description
      },
      {
        title: `${GetLocalizedTextItem(language, 'delete', 'upperAllFirst')} ${GetLocalizedTextItem(language, 'report', 'upperAllFirst')}`,
        onClick: deleteReport,
        className: "me-2",
        disabled: isLoading || !submittableDef.description,
        color: 'red'
      }] as ButtonConfig[] 
    }

    return [{
      title: GetLocalizedTextItem(language, 'createReport', 'upperAllFirst'),
      onClick: saveChanges,
      className: "me-2",
      disabled: isLoading || !submittableDef.description
    }] as ButtonConfig[] 

  }, [language, saveChanges, isLoading, submittableDef, deleteReport])

  return (
    <ModalBasic
      closer={props.closer}
      closeLabel={GetLocalizedTextItem(language, 'cancel', 'upperAllFirst')}
      headerText={GetLocalizedTextItem(language, submittableDef && submittableDef.id ? 'editReportSettings' : 'newReportSettings', 'upperAllFirst')}
      sz="md"
      footerClass="justify-content-right d-flex"
      buttonConfigs={buttonConfigs}
      closeRequested={closeRequested}
      closeable={!isLoading}
      showRequiredFieldsNote={true}
      yOffsetPx={40}
    >
      <ModalEditReportDefinitionBody
        def={props.def}
        setSubmittableDef={setSubmittableDef}
        requestReloadFunc={undefined}
      />
    </ModalBasic>
  )
}
