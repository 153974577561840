import { useMemo } from "react"

interface WordmarkProps {
  className?: string
}

export function Wordmark(props: WordmarkProps) {

  const { className } = props

  const classes = useMemo(() => {
    const base = "wordmark"
    if (!className) {
      return base
    }
    return `${base} ${className}`
  }, [className])

  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 156.26 17.98">
      <path d="M13.33,18.61,11.9,14.74H6.45L5,18.61H1.1L7.85,1.33h2.87l6.75,17.28ZM9.7,8.32a15.71,15.71,0,0,1-.51-1.59h0a16,16,0,0,1-.49,1.59L7.5,11.49h3.36Z" transform="translate(-1.1 -1.03)" />
      <path d="M30.5,19C25.77,19,21,15.92,21,10s4.89-9,9.43-9A10.4,10.4,0,0,1,36,2.46L35.2,5.9a8.13,8.13,0,0,0-4.35-1.21,5.39,5.39,0,0,0-5.72,5.48,5.27,5.27,0,0,0,5.56,5.32A4.51,4.51,0,0,0,33,15V12.27H30V9.07h6.8v8.17A10,10,0,0,1,30.5,19Z" transform="translate(-1.1 -1.03)" />
      <path d="M52.35,18.61l-4.6-6.75h-1.1v6.75h-4V1.46h6.26c2.93,0,5.53,2,5.53,5.19A4.75,4.75,0,0,1,51.81,11l5.46,7.66ZM48.29,4.82H46.65V8.66h1.64a1.94,1.94,0,0,0,2.15-1.9A2,2,0,0,0,48.29,4.82Z" transform="translate(-1.1 -1.03)" />
      <path d="M69,19a9,9,0,1,1,9.22-9A9,9,0,0,1,69,19ZM69,4.77a5.09,5.09,0,0,0-5,5.29,5,5,0,1,0,10,0A5.11,5.11,0,0,0,69,4.77Z" transform="translate(-1.1 -1.03)" />
      <path d="M83.67,18.61V1.46h4.16V15.19h6.45v3.42Z" transform="translate(-1.1 -1.03)" />
      <path d="M107.12,19a9,9,0,1,1,9.22-9A9,9,0,0,1,107.12,19Zm0-14.24a5.08,5.08,0,0,0-5,5.29,5,5,0,1,0,10,0A5.1,5.1,0,0,0,107.12,4.77Z" transform="translate(-1.1 -1.03)" />
      <path d="M130.81,19c-4.73,0-9.51-3.09-9.51-9s4.89-9,9.43-9a10.43,10.43,0,0,1,5.56,1.43l-.78,3.44a8.15,8.15,0,0,0-4.35-1.21,5.39,5.39,0,0,0-5.72,5.48A5.27,5.27,0,0,0,131,15.49a4.51,4.51,0,0,0,2.34-.51V12.27h-3V9.07h6.8v8.17A10,10,0,0,1,130.81,19Z" transform="translate(-1.1 -1.03)" />
      <path d="M151,11v7.66h-4V11l-6.42-9.57H145l2.82,4.11a21,21,0,0,1,1.24,2.07h.05a21,21,0,0,1,1.27-2.07l2.82-4.11h4.16Z" transform="translate(-1.1 -1.03)" />
    </svg>
  )
}