import { useMemo } from "react"
import { SelectEx, MenuTreeItem } from "./SelectEx"

import './Select.css'

interface SelectFlyoutProps {
  label?: string
  labelClass?: string
  children?: any
  onChange?: any
  defaultValue?: any
  disabled?: boolean
  className?: string
  wrapperClass?: string
  menuClassName?: string
  menu: MenuTreeItem[]
  disableTopLevelChecks?: boolean
  placeholder?: string
}

export default function SelectFlyout(props: SelectFlyoutProps) {

  const { menuClassName, disableTopLevelChecks, labelClass, className, wrapperClass } = props

  const menuClasses = useMemo(() => {
    if (menuClassName && disableTopLevelChecks) {
      return `${menuClassName} no-check-column`
    }
    if (disableTopLevelChecks) {
      return 'no-check-column'
    }
    if (menuClassName) {
      return menuClassName
    }
    return undefined
  }, [menuClassName, disableTopLevelChecks])

  const labelClasses = useMemo(() => {
    const base = 'select-label'
    if (labelClass) {
      return `${base} ${labelClass}`
    }
    return base
  }, [labelClass])

  const wrapperClasses = useMemo(() => {
    const base = 'select-wrapper'
    if (wrapperClass) {
      return `${base} ${wrapperClass}`
    }
    return base
  }, [wrapperClass])

  return (
    <>
      <div className={wrapperClasses}>
        {props.label && <div className={labelClasses}>
          {props.label}:
        </div>}
        <SelectEx
          onChange={props.onChange}
          menu={props.menu}
          menuClassName={menuClasses}
          className={className}
          defaultValue={props.defaultValue}
          placeholder={props.placeholder}
        />
      </div>
    </>
  )
}
