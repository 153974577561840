import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import './ButtonBasic.css'

function Caret() {
  return (
    <>
      <FontAwesomeIcon icon={faCaretDown} className="button-caret" />
    </>
  )
}

interface ButtonBasicProps {
  onClick?: any
  href?: string
  color?: string
  label: string
  labelColor?: string
  outline?: boolean
  className?: string
  icon?: IconDefinition
  disabled?: boolean
  hasMenu?: boolean
  size?: string
}

export default function ButtonBasic(props: ButtonBasicProps) {

  const { className, outline, labelColor, color, size } = props

  const classes = useMemo(() => {
    let baseClasses = 'button-basic'
    if (outline) {
      baseClasses += ' button-outline'
    }
    if (labelColor) {
      baseClasses += ` color-${labelColor}`
    }
    if (className) {
      baseClasses += ` ${className}`
    }
    if (size) {
      baseClasses += ` button-${size}`
    }
    if (color) {
      return `${baseClasses} button-${color}`
    }
    return `${baseClasses} button-blue`
  }, [color, labelColor, outline, size, className])

  if (props.href) {
    return (
      <Link className={classes} to={props.href}>
        <div className="button-basic-link-label">
          {props.icon && <FontAwesomeIcon icon={props.icon} className="button-icon" />}
          {props.label}
          {props.hasMenu && <Caret />}
        </div>
      </Link>
    )
  }

  return (
    <button onClick={props.onClick} className={classes} disabled={props.disabled}>
      <div className="button-basic-link-label">
        {props.icon && <FontAwesomeIcon icon={props.icon} className="button-icon" />}
        {props.label}
        {props.hasMenu && <Caret />}
      </div>
    </button>
  )
}