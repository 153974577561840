import { useMemo, useState } from 'react'
import { CollapseMenuItem } from './CollapseMenuItem'
import { ItemClickedFunc, MenuItem } from './MenuItem'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

import './CollapseMenu.css'

const isTouchScreen = navigator.maxTouchPoints && navigator.maxTouchPoints > 0

interface CollapseMenuProps {
  icon?: IconProp
  headerLabel: string,
  items: MenuItem[],
  posX: number,
  className?: string
}

export default function CollapseMenu(props: CollapseMenuProps) {

  const [isMenuVisible, setIsMenuVisible] = useState(false)
  const { className, icon } = props

  const classes = useMemo(() => {
    let base = 'collapse-menu-container'
    if (isTouchScreen) {
      base = `${base} ${base}-touch`
    }
    if (className) {
      return `${base} ${className}`
    }
    return base
  }, [className])

  const menuIcon = useMemo(() => {
    if (icon) {
      return icon
    }
    return faEllipsisVertical
  }, [icon])

  const menuItemClicked = (onClick?: ItemClickedFunc) => {
    if (onClick) {
      onClick()
    }
    setIsMenuVisible(false)
  }

  return (
    <div className={classes}>
      {!isMenuVisible &&
      <div
        className="collapse-menu-icon-area collapse-menu-icon-area-inactive"
        onClick={() => {setIsMenuVisible(!isMenuVisible)}}
      >
        <FontAwesomeIcon
        className="collapse-menu-icon"
        icon={menuIcon} />
      </div>
      }
      {isMenuVisible &&
      <div className="collapse-menu-mask">
        <div className="collapse-menu">
          <ul>
            <li className="collapse-menu-header add-border-bottom">
              <div
                className="collapse-menu-icon-area collapse-menu-icon-area-active"
                onClick={() => {setIsMenuVisible(!isMenuVisible)}}
              >
                <FontAwesomeIcon
                className="collapse-menu-icon"
                icon={menuIcon} />
              </div>
              <span className="collapse-menu-header-label">{props.headerLabel}</span>
            </li>
          {props.items.map((item, i) => {
            if (item.isSeparator) {
              return <hr className="separator" key={i} />
            }
            return <CollapseMenuItem
              onClick={() => {menuItemClicked(item.onClick)}}
              label={item.label || ''}
              isChecked={item.isChecked || false}
              key={i}
            />
          })}
          </ul>
        </div>
      </div>}
    </div>
  )
}